const manifest = {
  textures: [
    {
      name: 'opponent_atlas_01',
      url: '/textures/opponents/opponent_atlas_01.png',
    },
    {
      name: 'opponent_atlas_02',
      url: '/textures/opponents/opponent_atlas_02.png',
    },
    {
      name: 'cloudsTexture',
      url: '/textures/clouds-256.png',
    },
    {
      name: 'windowTexture',
      url: '/textures/roughness.png',
    },
    {
      name: 'emissiveTexture',
      url: '/textures/emissive.png',
    },
    {
      name: 'flare',
      url: '/textures/flare.png',
    },
    {
      name: 'geese_01',
      url: '/textures/geese-01.png',
    },
    {
      name: 'geese_02',
      url: '/textures/geese-02.png',
    },
    {
      name: 'geese_03',
      url: '/textures/geese-03.png',
    },
    {
      name: 'tpain1',
      url: '/textures/tpain1.png',
    },
    {
      name: 'tpain2',
      url: '/textures/tpain2.png',
    },
    {
      name: 'tpain3',
      url: '/textures/tpain3.png',
    },
    {
      name: 'tpain4',
      url: '/textures/tpain4.png',
    },
    {
      name: 'tpain5',
      url: '/textures/tpain5.png',
    },
    {
      name: 'tpain6',
      url: '/textures/tpain6.png',
    },
    {
      name: 'ball1',
      url: '/textures/ball1.png',
    },
    {
      name: 'ball2',
      url: '/textures/ball2.png',
    },
    {
      name: 'ball3',
      url: '/textures/ball3.png',
    },
    {
      name: 'ball4',
      url: '/textures/ball4.png',
    },
    {
      name: 'ball5',
      url: '/textures/ball5.png',
    },
    {
      name: 'ball6',
      url: '/textures/ball6.png',
    },
    {
      name: 'ball7',
      url: '/textures/ball7.png',
    },
    {
      name: 'ball8',
      url: '/textures/ball8.png',
    },
    {
      name: 'diggad1',
      url: '/textures/diggad1.png',
    },
    {
      name: 'diggad2',
      url: '/textures/diggad2.png',
    },
    {
      name: 'diggad3',
      url: '/textures/diggad3.png',
    },
    {
      name: 'diggad4',
      url: '/textures/diggad4.png',
    },
    {
      name: 'diggad5',
      url: '/textures/diggad5.png',
    },
    {
      name: 'diggad6',
      url: '/textures/diggad6.png',
    },
    {
      name: 'diggad7',
      url: '/textures/diggad7.png',
    },
    {
      name: 'diggad8',
      url: '/textures/diggad8.png',
    },
    {
      name: 'diggad9',
      url: '/textures/diggad9.png',
    },
    {
      name: 'hairgirl1',
      url: '/textures/hairgirl1.png',
    },
    {
      name: 'hairgirl2',
      url: '/textures/hairgirl2.png',
    },
    {
      name: 'hairgirl3',
      url: '/textures/hairgirl3.png',
    },
    {
      name: 'hairgirl4',
      url: '/textures/hairgirl4.png',
    },
    {
      name: 'kehlani1',
      url: '/textures/kehlani1.png',
    },
    {
      name: 'kehlani2',
      url: '/textures/kehlani2.png',
    },
    {
      name: 'kehlani3',
      url: '/textures/kehlani3.png',
    },
    {
      name: 'kehlani4',
      url: '/textures/kehlani4.png',
    },
    {
      name: 'mabel1',
      url: '/textures/mabel1.png',
    },
    {
      name: 'mabel2',
      url: '/textures/mabel2.png',
    },
    {
      name: 'mabel3',
      url: '/textures/mabel3.png',
    },
    {
      name: 'mabel4',
      url: '/textures/mabel4.png',
    },
    {
      name: 'mabel5',
      url: '/textures/mabel5.png',
    },
    {
      name: 'mabel6',
      url: '/textures/mabel6.png',
    },
    {
      name: 'mabel7',
      url: '/textures/mabel7.png',
    },
    {
      name: 'mabel8',
      url: '/textures/mabel8.png',
    },
    {
      name: 'mabel9',
      url: '/textures/mabel9.png',
    },
    {
      name: 'mabel10',
      url: '/textures/mabel10.png',
    },
    {
      name: 'millie1',
      url: '/textures/millie1.png',
    },
    {
      name: 'millie2',
      url: '/textures/millie2.png',
    },
    {
      name: 'millie3',
      url: '/textures/millie3.png',
    },
    {
      name: 'millie4',
      url: '/textures/millie4.png',
    },
    {
      name: 'millie5',
      url: '/textures/millie5.png',
    },
    {
      name: 'millie6',
      url: '/textures/millie6.png',
    },
    {
      name: 'millie7',
      url: '/textures/millie7.png',
    },
    {
      name: 'millie8',
      url: '/textures/millie8.png',
    },
    {
      name: 'mostack1',
      url: '/textures/mostack1.png',
    },
    {
      name: 'mostack2',
      url: '/textures/mostack2.png',
    },
    {
      name: 'mostack3',
      url: '/textures/mostack3.png',
    },
    {
      name: 'mostack4',
      url: '/textures/mostack4.png',
    },
    {
      name: 'mostack5',
      url: '/textures/mostack5.png',
    },
    {
      name: 'mostack6',
      url: '/textures/mostack6.png',
    },
    {
      name: 'mostack7',
      url: '/textures/mostack7.png',
    },
    {
      name: 'mostack8',
      url: '/textures/mostack8.png',
    },
    {
      name: 'nonchalant1',
      url: '/textures/nonchalant1.png',
    },
    {
      name: 'nonchalant2',
      url: '/textures/nonchalant2.png',
    },
    {
      name: 'nonchalant3',
      url: '/textures/nonchalant3.png',
    },
    {
      name: 'nonchalant4',
      url: '/textures/nonchalant4.png',
    },
    {
      name: 'nonchalant5',
      url: '/textures/nonchalant5.png',
    },
    {
      name: 'nonchalant6',
      url: '/textures/nonchalant6.png',
    },
    {
      name: 'nonchalant7',
      url: '/textures/nonchalant7.png',
    },
    {
      name: 'phone1',
      url: '/textures/phone1.png',
    },
    {
      name: 'phone2',
      url: '/textures/phone2.png',
    },
    {
      name: 'phone3',
      url: '/textures/phone3.png',
    },
    {
      name: 'phone4',
      url: '/textures/phone4.png',
    },
    {
      name: 'sahbabi1',
      url: '/textures/sahbabi1.png',
    },
    {
      name: 'sahbabi2',
      url: '/textures/sahbabi2.png',
    },
    {
      name: 'sahbabi3',
      url: '/textures/sahbabi3.png',
    },
    {
      name: 'sahbabi4',
      url: '/textures/sahbabi4.png',
    },
    {
      name: 'sahbabi5',
      url: '/textures/sahbabi5.png',
    },
    {
      name: 'sahbabi6',
      url: '/textures/sahbabi6.png',
    },
    {
      name: 'sahbabi7',
      url: '/textures/sahbabi7.png',
    },
    {
      name: 'sahbabi8',
      url: '/textures/sahbabi8.png',
    },
    {
      name: 'sahbabi9',
      url: '/textures/sahbabi9.png',
    },
    {
      name: 'sahbabi10',
      url: '/textures/sahbabi10.png',
    },
    {
      name: 'sahbabi11',
      url: '/textures/sahbabi11.png',
    },
  ],
  glb: [
    {
      name: 'basketball',
      url: '/models/basketball-01.glb',
    },
    {
      name: 'ladbroke',
      url: '/models/ladbroke-01.glb',
    },
    {
      name: 'skydome',
      url: '/models/skydome-01.glb',
    },
    {
      name: 'clouds',
      url: '/models/clouds-01.glb',
    },
    {
      name: 'geese',
      url: '/models/geese-01.glb',
    },
    {
      name: 'opponents',
      url: '/models/opponents-02.glb',
    },
    {
      name: 'basicgirl_01_collider',
      url: '/models/colliders/basicgirl_01.glb',
    },
    {
      name: 'bossman_01_collider',
      url: '/models/colliders/bossman_01.glb',
    },
    {
      name: 'businessman_01_collider',
      url: '/models/colliders/businessman_01.glb',
    },
    {
      name: 'dog_01_collider',
      url: '/models/colliders/dog_01.glb',
    },
    {
      name: 'hipster_01_collider',
      url: '/models/colliders/hipster_01.glb',
    },
    {
      name: 'oldlady_01_collider',
      url: '/models/colliders/oldlady_01.glb',
    },
    {
      name: 'pig_01_collider',
      url: '/models/colliders/pig_01.glb',
    },
    {
      name: 'pizza_01_collider',
      url: '/models/colliders/pizza_01.glb',
    },
    {
      name: 'poshboy_01_collider',
      url: '/models/colliders/poshboy_01.glb',
    },
    {
      name: 'scootergirl_01_collider',
      url: '/models/colliders/scootergirl_01.glb',
    },
    {
      name: 'sign_01_collider',
      url: '/models/colliders/sign_01.glb',
    },
    {
      name: 'basicgirl_02_collider',
      url: '/models/colliders/basicgirl_02.glb',
    },
    {
      name: 'bossman_02_collider',
      url: '/models/colliders/bossman_02.glb',
    },
    {
      name: 'businessman_02_collider',
      url: '/models/colliders/businessman_02.glb',
    },
    {
      name: 'dog_02_collider',
      url: '/models/colliders/dog_02.glb',
    },
    {
      name: 'hipster_02_collider',
      url: '/models/colliders/hipster_02.glb',
    },
    {
      name: 'oldlady_02_collider',
      url: '/models/colliders/oldlady_02.glb',
    },
    {
      name: 'pig_02_collider',
      url: '/models/colliders/pig_02.glb',
    },
    {
      name: 'pizza_02_collider',
      url: '/models/colliders/pizza_02.glb',
    },
    {
      name: 'poshboy_02_collider',
      url: '/models/colliders/poshboy_02.glb',
    },
    {
      name: 'scootergirl_02_collider',
      url: '/models/colliders/scootergirl_02.glb',
    },
    {
      name: 'crowd',
      url: '/models/crowd-02.glb',
    },
    {
      name: 'sign_02_collider',
      url: '/models/colliders/sign_02.glb',
    },
    {
      name: 'ball',
      url: '/models/basketball-02.glb',
    },
  ],
};

export default manifest;
