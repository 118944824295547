import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LEVEL } from '../../common/constants/levels';

import WebGL from '../../webgl';

const StyledCanvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const Canvas = () => {
  const canvasRef = useRef(null);
  const webGLRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      webGLRef.current = new WebGL({
        canvas: canvasRef.current,
        config: LEVEL[1],
        level: 1,
      });
    }
  }, []);

  return (
    <StyledCanvas
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
};
