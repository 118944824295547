import { Vector3 } from 'three';
import DIRECTION from './opponentDirections';
import OPPONENT_TYPES from './opponentTypes';

import level1TitleAsset from '../../assets/images/level-transition-titles/BOOM.png';
import level2TitleAsset from '../../assets/images/level-transition-titles/BROOOO.png';
import level3TitleAsset from '../../assets/images/level-transition-titles/HOLD TIGHT.png';
import level4TitleAsset from '../../assets/images/level-transition-titles/LEVELS.png';
import level5TitleAsset from '../../assets/images/level-transition-titles/NBN.png';
import level6TitleAsset from '../../assets/images/level-transition-titles/THAT_S A FLEX.png';
import level7TitleAsset from '../../assets/images/level-transition-titles/OOOOOF.png';
import level8TitleAsset from '../../assets/images/level-transition-titles/MAD.png';
import level9TitleAsset from '../../assets/images/level-transition-titles/YEAH.png';
import level10TitleAsset from '../../assets/images/level-transition-titles/JHEEEZE.png';
import level11TitleAsset from '../../assets/images/level-transition-titles/SWOOSH.png';
import level12TitleAsset from '../../assets/images/level-transition-titles/HUSTLE.png';
import level13TitleAsset from '../../assets/images/level-transition-titles/100.png';
import level14TitleAsset from '../../assets/images/level-transition-titles/HARD.png';
import level15TitleAsset from '../../assets/images/level-transition-titles/RAAAA.png';
import level16TitleAsset from '../../assets/images/level-transition-titles/THAT_S A WRAP.png';

// eslint-disable-next-line import/no-duplicates
import level1HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_talk_1.png';
// eslint-disable-next-line import/no-duplicates
import level2HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_talk_1.png';
// eslint-disable-next-line import/no-duplicates
import level3HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_sunglasses_talk_3.png';
// eslint-disable-next-line import/no-duplicates
import level4HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_talk_blink_2.png';
// eslint-disable-next-line import/no-duplicates
import level5HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_hat_1.png';
// eslint-disable-next-line import/no-duplicates
import level6HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_sunglassesonnose_talk_blink_3.png';
// eslint-disable-next-line import/no-duplicates
import level7HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_tilt_2.png';
// eslint-disable-next-line import/no-duplicates
import level8HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_sunglasses_talk_3.png';
// eslint-disable-next-line import/no-duplicates
import level9HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_talk_1.png';
// eslint-disable-next-line import/no-duplicates
import level10HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_tilt_2.png';
// eslint-disable-next-line import/no-duplicates
import level11HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_talk_blink_2.png';
// eslint-disable-next-line import/no-duplicates
import level12HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_hat_1.png';
// eslint-disable-next-line import/no-duplicates
import level13HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_sunglasses_talk_3.png';
// eslint-disable-next-line import/no-duplicates
import level14HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_talk_1.png';
// eslint-disable-next-line import/no-duplicates
import level15HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_sunglassesonnose_talk_blink_3.png';
// eslint-disable-next-line import/no-duplicates
import level16HeadAsset from '../../assets/images/level-transition-heads/AJ_UpperBody_sunglasses_talk_3.png';

export const LEVEL = {
  1: {
    id: 1,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level1TitleAsset, minWidth: 190, maxWidth: 411 },
      head: { asset: level1HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level1Asset',
    },
    ball: {
      velocity: 5.2,
      elevation: 30,
    },
    camera: {
      startPosition: new Vector3(0.2, 5, 5),
      startTarget: new Vector3(0.198, 4.999, 4.99),
      duration: 10,
      position: new Vector3(0.2, 1.4, 5),
      target: new Vector3(0.198, 1.404, 4.99),
    },
  },
  2: {
    id: 2,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level2TitleAsset, minWidth: 252, maxWidth: 544 },
      head: { asset: level2HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level2Asset',
    },
    ball: {
      velocity: 5.3,
      elevation: 30,
    },
    camera: {
      position: new Vector3(1, 1.4, 5.5),
      target: new Vector3(0.996, 1.402, 5.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.BUSINESS_MAN,
        id: 'businessMan',
        offset: 3,
        duration: 3,
        speed: 0.2,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0,
              to: 0.5,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.5,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.33,
            jumpVelocity: 3.4,
          },
          {
            time: 0.6,
            jumpVelocity: 3.4,
          },
        ],
      },
    ],
  },
  3: {
    id: 3,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level3TitleAsset, minWidth: 207, maxWidth: 446 },
      head: { asset: level3HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level3Asset',
    },
    ball: {
      velocity: 5.5,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-1.5, 1.4, 5.75),
      target: new Vector3(-1.495, 1.402, 5.74),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.SIGN,
        id: 'sign',
        offset: 4,
        duration: 4,
        speed: 1,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0,
              to: 0.125,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.125,
              to: 0.375,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.375,
              to: 0.5,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.5,
              to: 0.625,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.625,
              to: 0.875,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.875,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.625,
            jumpVelocity: 5,
          },
          {
            time: 0.875,
            jumpVelocity: 5,
          },
        ],
      },
    ],
  },
  4: {
    id: 4,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level4TitleAsset, minWidth: 233, maxWidth: 504 },
      head: { asset: level4HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level4Asset',
    },
    camera: {
      position: new Vector3(0.5, 1.4, 6.4),
      target: new Vector3(0.495, 1.403, 6.39),
    },
    ball: {
      velocity: 5.6,
      elevation: 30,
    },
    opponents: [
      {
        type: OPPONENT_TYPES.POSH_BOY,
        id: 'poshBoy',
        offset: 3,
        duration: 3,
        speed: 0.3,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.2,
            jumpVelocity: 3.5,
          },
          {
            time: 0.45,
            jumpVelocity: 3.5,
          },
        ],
      },
    ],
  },
  5: {
    id: 5,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level5TitleAsset, minWidth: 136, maxWidth: 295 },
      head: { asset: level5HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level5Asset',
    },
    ball: {
      velocity: 5.9,
      elevation: 30,
    },
    camera: {
      position: new Vector3(2.5, 1.4, 6.5),
      target: new Vector3(2.494, 1.401, 6.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.PIZZA,
        id: 'pizza',
        offset: 3,
        duration: 8,
        speed: 0.1,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 2.5,
          },
          {
            time: 0.75,
            jumpVelocity: 2.5,
          },
        ],
      },
    ],
  },
  6: {
    id: 6,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level6TitleAsset, minWidth: 230, maxWidth: 496 },
      head: { asset: level6HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level6Asset',
    },
    ball: {
      velocity: 6.1,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-3.5, 1.4, 6.75),
      target: new Vector3(-3.494, 1.402, 6.745),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.BASIC_GIRL,
        id: 'basicGirl',
        offset: 3,
        duration: 6,
        speed: 1,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.125,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.125,
              to: 0.25,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.25,
              to: 0.375,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.375,
              to: 0.625,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.625,
              to: 0.75,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.75,
              to: 0.875,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.875,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0,
            jumpVelocity: 3,
          },
          {
            time: 0.2,
            jumpVelocity: 2.5,
          },
          {
            time: 0.4,
            jumpVelocity: 2.7,
          },
          {
            time: 0.8,
            jumpVelocity: 2.8,
          },
        ],
      },
    ],
  },
  7: {
    id: 7,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level7TitleAsset, minWidth: 247, maxWidth: 534 },
      head: { asset: level7HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level7Asset',
    },
    ball: {
      velocity: 6.3,
      elevation: 30,
    },
    camera: {
      position: new Vector3(3, 1.4, 7.5),
      target: new Vector3(2.994, 1.401, 7.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.SCOOTER_GIRL,
        id: 'scooterGirl',
        offset: 4,
        duration: 5,
        speed: 0.4,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0.25,
              to: 0.375,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.375,
              to: 0.625,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.625,
              to: 0.75,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 6,
          },
        ],
      },
    ],
  },
  8: {
    id: 8,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level8TitleAsset, minWidth: 148, maxWidth: 321 },
      head: { asset: level8HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level8Asset',
    },
    ball: {
      velocity: 6.6,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-3.5, 1.4, 8),
      target: new Vector3(-3.495, 1.401, 7.99),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.HIPSTER,
        id: 'hipster',
        offset: 4,
        duration: 5,
        speed: 0.2,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.75,
            jumpVelocity: 2.2,
          },
        ],
      },
    ],
  },
  9: {
    id: 9,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level9TitleAsset, minWidth: 190, maxWidth: 411 },
      head: { asset: level9HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level9Asset',
    },
    ball: {
      velocity: 6.6,
      elevation: 30,
    },
    camera: {
      position: new Vector3(5, 1.4, 7.5),
      target: new Vector3(4.99, 1.4, 7.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.DOG,
        id: 'dog',
        offset: 4,
        duration: 5,
        speed: 0.4,
        movement: {
          steps: [
            {
              direction: DIRECTION.RIGHT,
              from: 0,
              to: 0.2,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.2,
              to: 0.4,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.4,
              to: 0.6,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.6,
              to: 0.8,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.8,
              to: 0.9,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.9,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.2,
            jumpVelocity: 6,
          },
          {
            time: 0.5,
            jumpVelocity: 6,
          },
          {
            time: 0.7,
            jumpVelocity: 6,
          },
        ],
      },
    ],
  },
  10: {
    id: 10,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level10TitleAsset, minWidth: 262, maxWidth: 566 },
      head: { asset: level10HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level10Asset',
    },
    ball: {
      velocity: 6.8,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-5.5, 1.4, 7.5),
      target: new Vector3(-5.491, 1.401, 7.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.OLD_LADY,
        id: 'oldLady',
        offset: 4,
        duration: 5,
        speed: 0.3,
        movement: {
          steps: [
            {
              direction: DIRECTION.BACK,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 2.5,
          },
        ],
      },
    ],
  },
  11: {
    id: 11,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level11TitleAsset, minWidth: 275, maxWidth: 594 },
      head: { asset: level11HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level11Asset',
    },
    ball: {
      velocity: 6.7,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-1, 1.4, 9.5),
      target: new Vector3(-0.999, 1.401, 9.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.BOSS_MAN,
        id: 'bossMan',
        offset: 4,
        duration: 5,
        speed: 0.4,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 3.2,
          },
        ],
      },
    ],
  },
  12: {
    id: 12,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level12TitleAsset, minWidth: 243, maxWidth: 524 },
      head: { asset: level12HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level12Asset',
    },
    ball: {
      velocity: 6.75,
      elevation: 30,
    },
    camera: {
      position: new Vector3(4.5, 1.4, 9),
      target: new Vector3(4.49, 1.4, 8.99),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.BUSINESS_MAN,
        id: 'businessMan',
        offset: 3,
        duration: 3,
        speed: 0.2,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0,
              to: 0.5,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.5,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.33,
            jumpVelocity: 3.4,
          },
          {
            time: 0.6,
            jumpVelocity: 3.4,
          },
        ],
      },
      {
        type: OPPONENT_TYPES.POSH_BOY,
        id: 'poshBoy',
        offset: 3,
        duration: 3,
        speed: 0.3,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.2,
            jumpVelocity: 3.5,
          },
          {
            time: 0.45,
            jumpVelocity: 3.5,
          },
        ],
      },
    ],
  },
  13: {
    id: 13,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level13TitleAsset, minWidth: 122, maxWidth: 264 },
      head: { asset: level13HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level13Asset',
    },
    ball: {
      velocity: 7.2,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-5.5, 1.4, 9),
      target: new Vector3(-5.49, 1.4, 8.99),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.SCOOTER_GIRL,
        id: 'scooterGirl',
        offset: 6,
        duration: 5,
        speed: 0.4,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0.25,
              to: 0.375,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.375,
              to: 0.625,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.625,
              to: 0.75,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 6,
          },
        ],
      },
      {
        type: OPPONENT_TYPES.SIGN,
        id: 'sign',
        offset: 4,
        duration: 4,
        speed: 1,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0,
              to: 0.125,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.125,
              to: 0.375,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.375,
              to: 0.5,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.5,
              to: 0.625,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.625,
              to: 0.875,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.875,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.625,
            jumpVelocity: 5,
          },
          {
            time: 0.875,
            jumpVelocity: 5,
          },
        ],
      },
    ],
  },
  14: {
    id: 15,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level14TitleAsset, minWidth: 168, maxWidth: 364 },
      head: { asset: level14HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level14Asset',
    },
    ball: {
      velocity: 7.2,
      elevation: 30,
    },
    camera: {
      position: new Vector3(6.5, 1.4, 9),
      target: new Vector3(6.49, 1.4, 8.99),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.PIZZA,
        id: 'pizza',
        offset: 5,
        duration: 8,
        speed: 0.1,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 3.5,
          },
          {
            time: 0.75,
            jumpVelocity: 3.5,
          },
        ],
      },
      {
        type: OPPONENT_TYPES.BASIC_GIRL,
        id: 'basicGirl',
        offset: 3,
        duration: 6,
        speed: 1,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.125,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.125,
              to: 0.25,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.25,
              to: 0.375,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.375,
              to: 0.625,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.625,
              to: 0.75,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.75,
              to: 0.875,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.875,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0,
            jumpVelocity: 3,
          },
          {
            time: 0.2,
            jumpVelocity: 2.5,
          },
          {
            time: 0.4,
            jumpVelocity: 2.7,
          },
          {
            time: 0.8,
            jumpVelocity: 2.8,
          },
        ],
      },
    ],
  },
  15: {
    id: 15,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level15TitleAsset, minWidth: 210, maxWidth: 533 },
      head: { asset: level15HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level15Asset',
    },
    ball: {
      velocity: 7.4,
      elevation: 30,
    },
    camera: {
      position: new Vector3(-7, 1.4, 9.5),
      target: new Vector3(-6.99, 1.4035, 9.49),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.BOSS_MAN,
        id: 'bossMan',
        offset: 4,
        duration: 5,
        speed: 0.4,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.25,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.25,
              to: 0.75,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.75,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.25,
            jumpVelocity: 3.2,
          },
        ],
      },
      {
        type: OPPONENT_TYPES.SIGN,
        id: 'sign',
        offset: 4,
        duration: 4,
        speed: 1,
        movement: {
          steps: [
            {
              direction: DIRECTION.LEFT,
              from: 0,
              to: 0.125,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.125,
              to: 0.375,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.375,
              to: 0.5,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.5,
              to: 0.625,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.625,
              to: 0.875,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.875,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.625,
            jumpVelocity: 5,
          },
          {
            time: 0.875,
            jumpVelocity: 5,
          },
        ],
      },
      {
        type: OPPONENT_TYPES.DOG,
        id: 'dog',
        offset: 4,
        duration: 5,
        speed: 0.4,
        movement: {
          steps: [
            {
              direction: DIRECTION.RIGHT,
              from: 0,
              to: 0.1,
            },
            {
              direction: DIRECTION.FORWARD,
              from: 0.1,
              to: 0.4,
            },
            {
              direction: DIRECTION.LEFT,
              from: 0.4,
              to: 0.6,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.6,
              to: 0.9,
            },
            {
              direction: DIRECTION.RIGHT,
              from: 0.9,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.2,
            jumpVelocity: 6,
          },
          {
            time: 0.5,
            jumpVelocity: 6,
          },
          {
            time: 0.7,
            jumpVelocity: 6,
          },
        ],
      },
    ],
  },
  16: {
    id: 16,
    name: '',
    count: 30,
    goal: 1,
    transitions: {
      title: { asset: level16TitleAsset, minWidth: 447, maxWidth: 619 },
      head: { asset: level16HeadAsset, minWidth: 179, maxWidth: 531 },
      audio: 'level16Asset',
    },
    ball: {
      velocity: 7.7,
      elevation: 30,
    },
    camera: {
      position: new Vector3(4, 1.4, 12),
      target: new Vector3(3.99, 1.4, 11.98),
    },
    opponents: [
      {
        type: OPPONENT_TYPES.PIG,
        id: 'pig',
        offset: 6,
        duration: 6,
        speed: 0.8,
        movement: {
          steps: [
            {
              direction: DIRECTION.FORWARD,
              from: 0,
              to: 0.5,
            },
            {
              direction: DIRECTION.BACK,
              from: 0.5,
              to: 1,
            },
          ],
        },
        jumps: [
          {
            time: 0.1,
            jumpVelocity: 3,
          },
          {
            time: 0.25,
            jumpVelocity: 3,
          },
          {
            time: 0.45,
            jumpVelocity: 3,
          },
          {
            time: 0.6,
            jumpVelocity: 3,
          },
          {
            time: 0.8,
            jumpVelocity: 3,
          },
          {
            time: 0.95,
            jumpVelocity: 3,
          },
        ],
      },
    ],
  },
};
