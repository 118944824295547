import gsap, { Power4 } from 'gsap';

import {
  Mesh,
  MeshBasicMaterial,
  Raycaster,
  SphereGeometry,
  Vector2,
  Vector3,
  LinearEncoding,
} from 'three';
import { EVENT } from '../../../common/constants/events';
import { Camera, config, CURRENT_SCENE, SIZES } from '../../globals/constants';
import { Cache } from '../../utils/cache';
import Broadcaster from '../../../utils/Broadcaster';

export default class Basketball extends Mesh {
  constructor(first = false) {
    const radius = 0.125;
    const geometry = new SphereGeometry(radius, 64, 64);
    const material = new MeshBasicMaterial({ transparent: true, opacity: 0 });

    // Make mesh
    super(geometry, material);

    // If it's the first ball on screen, we set the position to the end position of the camera move.
    this.first = first;

    // Reposition and add to the scene
    this.position.copy(Camera.position);
    this.localTranslation = new Vector3(0, 0, 0);
    CURRENT_SCENE.add(this);

    this.ballMesh = Cache.get('ball').scene.clone();
    this.ballMesh.position.copy(this.position);

    this.baseRotation = Math.random() * Math.PI * 2;

    this.ballMesh.traverse((objectType) => {
      if (objectType.isMesh) {
        if (objectType.material.map) {
          objectType.material.map.encoding = LinearEncoding;
          objectType.material.roughness = 0.7;
        }
      }
    });
    CURRENT_SCENE.add(this.ballMesh);

    // Raycaster
    this.raycaster = new Raycaster();
    const rayOrigin = new Vector3(0, 0, 5);
    const rayDirection = new Vector3(0, 0, -1).normalize();
    this.raycaster.set(rayOrigin, rayDirection);

    this.animateIn();
  }

  activate(e) {
    this.moveInAnimation = gsap.to(this.localTranslation, {
      z: 0.02,
      duration: 1,
      ease: 'expo',
    });
  }

  release(e) {
    Broadcaster.emit(EVENT.SHOOT_BALL);
  }

  animateIn() {
    gsap.fromTo(
      this.localTranslation,
      { y: -1 },
      { y: 0, duration: 1, ease: Power4.easeOut }
    );
  }

  animateOutAndRemove() {
    CURRENT_SCENE.remove(this);
    CURRENT_SCENE.remove(this.ballMesh);
  }

  keepInFrontCamera() {
    const cameraPosition = this.first
      ? config.camera.position
      : Camera.position;
    this.position.copy(cameraPosition);
    this.rotation.copy(Camera.rotation);
    this.translateZ(this.localTranslation.z - 0.25);
    this.translateY(this.localTranslation.y - 0.25);
  }

  updateBallMesh() {
    this.ballMesh.position.copy(this.position);
    this.ballMesh.rotation.set(
      this.rotation.x,
      this.rotation.y,
      this.rotation.z + this.baseRotation
    );
  }

  isClicked(x, y) {
    const mouse = new Vector2(
      (x / SIZES.width) * 2 - 1,
      -((y / SIZES.height) * 2 - 1)
    );
    this.raycaster.setFromCamera(mouse, Camera.orbitControls.object);
    return this.raycaster.intersectObject(this).length > 0 ? true : false;
  }
}
