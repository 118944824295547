import { RESPONSIVE } from '../common/constants/responsive';

export function minMaxSizing(sizes) {
  const {
    css,
    min,
    max,
    unit = 'px',
    minWidth = RESPONSIVE.MIN_DEFAULT,
    maxWidth = RESPONSIVE.MAX_DEFAULT,
  } = sizes;

  return `
    ${css}: ${min}${unit};

    @media (min-width: ${minWidth}px) {
      ${css}: calc(${min}${unit} + (${max} - ${min}) * ((100vw - ${minWidth}${unit}) / (${maxWidth} - ${minWidth})));
    };
    
    @media (min-width: ${maxWidth}px) {
      ${css}: ${max}${unit};
    }
  `;
}
