import BB1Asset from '../assets/images/basketball-text/1.svg';
import BB2Asset from '../assets/images/basketball-text/2.svg';
import BB3Asset from '../assets/images/basketball-text/3.svg';

export const renderBBNumeral = (count) => {
  switch (count) {
    case 1:
      return BB1Asset;
    case 2:
      return BB2Asset;
    case 3:
      return BB3Asset;

    default:
      break;
  }
};
