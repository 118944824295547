import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CTA } from '../../../CTA/CTA';
import { downloadURI } from '../../../../utils/download-uri';
import { minMaxSizing } from '../../../../utils/typeStyling';
import { trackEvent } from '../../../../utils/trackEvent';
import { ACTION, CATEGORY } from '../../../../common/constants/tracking';

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${minMaxSizing({ css: 'margin-top', min: 8, max: 16 })};
  ${minMaxSizing({ css: 'margin-bottom', min: 8, max: 16 })};
`;

export const ScorecardDownload = ({ asset, grade }) => {
  const download = useCallback(() => {
    trackEvent(CATEGORY.OUTRO, ACTION.CLICK, `download-grade-${grade}`);
    downloadURI(asset, '');
  }, [asset, grade]);

  return (
    <Wrapper>
      <CTA text="Save your card" onClick={download} />
    </Wrapper>
  );
};

ScorecardDownload.propTypes = {
  asset: PropTypes.string,
  grade: PropTypes.string,
};
