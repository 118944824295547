export const EVENT = {
  // Old/testing events(?)
  SHOOT_BALL: 'shoot_ball',

  // React driven events
  INCREASE_LEVEL: 'increase_level', // Level increase after required goals scored
  TIME_OUT: 'time_out', // Countdown times out
  GOAL_REACHED: 'goal_reached', // Level goal reached (starts transition sequence)
  GAME_START: 'game_start', // Game start (after initial countdown has finished?)
  GAME_PAUSED: 'game_start', // Game paused (for level transitions)
  GAME_OVER: 'game_over', // Game over event to pause running
  GAME_WON: 'game_over', // Game over event to pause running
  GAME_RESET: 'game_reset', // Game over event to pause running

  TIMER_START: 'timer_start',
  TIMER_PAUSE: 'timer_pause',
  TIMER_RESET: 'timer_RESET',

  // Game driven events
  INCREASE_SCORE: 'increase_score', // Goal scored
  GAME_LOADED: 'game_loaded', // WebGL assets loaded
  LEVEL_UPDATE: 'level_update', // Level updated
  START_TIMER: 'start_timer', // Level updated

  TOGGLE_SFX: 'toggle_sfx',
};
