import { createSlice } from '@reduxjs/toolkit';
import Broadcaster from '../../utils/Broadcaster';
import { EVENT } from '../../common/constants/events';

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    score: 0,
    gameStatus: false,
    level: 1,
  },
  reducers: {
    increaseScore: (state) => {
      state.score += 1;
    },
    resetScore: (state) => {
      state.score = 0;
    },
    setLevel: (state, action) => {
      state.level = action.payload;
      Broadcaster.emit(EVENT.LEVEL_UPDATE, state.level);
    },
    increaseLevel: (state) => {
      state.level += 1;
      Broadcaster.emit(EVENT.LEVEL_UPDATE, state.level);
    },
    resetLevel: (state) => {
      state.level = 1;
    },
    jump: () => {
      Broadcaster.emit(EVENT.JUMP);
    },
    setGameStatus: (state, action) => {
      state.gameStatus = action.payload;
    },
  },
});

// Export actions
export const {
  increaseScore,
  resetScore,
  resetBall,
  setLevel,
  increaseLevel,
  resetLevel,
  jump,
  setGameStatus,
} = gameSlice.actions;

// State selectors
export const selectScore = (state) => state.game.score;
export const selectGameStatus = (state) => state.game.gameStatus;
export const selectLevel = (state) => state.game.level;

export default gameSlice.reducer;
