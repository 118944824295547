import { Color, Mesh, PlaneGeometry, ShaderMaterial } from 'three';
import {
  CURRENT_SCENE,
  DEBUG_WINDOWS,
  timeOfDay,
} from '../../globals/constants';

export default class SkydomeShader {
  constructor() {
    const uniforms = {
      uHorizonStopMix: { value: 0 },
      uMidStopMix: { value: 0 },
      uZenithStopMix: { value: 0 },
      uHorizonMix: { value: new Color(0xb9b9b9) },
      uMidMix: { value: new Color(0xdfd2bf) },
      uZenithMix: { value: new Color(0x575757) },
    };

    const vertexShader = `
    varying vec3 vPosLocal;
    void main() {
      vPosLocal = position;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `;

    const fragmentShader = `
      #ifndef OPTI
      uniform float uHorizonStopMix;
      uniform float uMidStopMix;
      uniform float uZenithStopMix;
      uniform vec3 uHorizonMix;
      uniform vec3 uMidMix;
      uniform vec3 uZenithMix;
      #endif
      varying vec3 vPosLocal;
      void main() {
        float normalisedYPos = vPosLocal.y/800.;

        vec3 colorOut = mix(uHorizonMix,uMidMix,smoothstep(uHorizonStopMix,uMidStopMix,normalisedYPos));
        colorOut = mix(colorOut.xyz,uZenithMix,smoothstep(uMidStopMix,uZenithStopMix,normalisedYPos));
        
        gl_FragColor = vec4(colorOut,1.);
      }`;

    this.material = new ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
    });
  }
}
