import {
  MeshStandardMaterial,
  NearestFilter,
  LinearEncoding,
  Color,
} from 'three';
import { CURRENT_SCENE, Camera } from '../globals/constants';
import { Cache } from '../utils/cache';
export default class Crowd {
  constructor() {
    const glb = Cache.get('crowd'); // This gets the main glb file from the cache.
    const emissiveLight = new Color(0xffffff);

    this.frameCounts = {
      tpain: 6,
      ball: 8,
      diggad: 9,
      hairgirl: 4,
      kehlani: 4,
      mabel: 10,
      millie: 8,
      mostack: 8,
      nonchalant: 7,
      phone: 4,
      sahbabi: 11,
    };

    this.animCounter = 0;
    this.frameRate = 10;
    this.cumulativeTime = 0;

    glb.scene.traverse((objectType) => {
      if (objectType.isMesh) {
        objectType.material = new MeshStandardMaterial();
        objectType.material.transparent = true;
        objectType.material.alphaTest = 0.5;
        objectType.material.map = Cache.get(objectType.name + '1');
        objectType.material.map.minFilter = NearestFilter;
        objectType.material.map.magFilter = NearestFilter;
        objectType.material.map.encoding = LinearEncoding;
        objectType.material.emissive = emissiveLight;
        objectType.material.emissiveIntensity = 0;
        objectType.material.fog = false;
        objectType.material.needsUpdate = true;
        objectType.visible = false;
      }
    });

    this.mesh = glb.scene;
    CURRENT_SCENE.add(this.mesh);
  }

  update(deltaSeconds) {
    this.cumulativeTime += deltaSeconds;
    if (this.cumulativeTime >= 1 / this.frameRate) {
      this.animCounter++;
      this.cumulativeTime = 0;
    }

    const angle = Camera.orbitControls.getAzimuthalAngle();
    this.mesh.traverse((objectType) => {
      if (objectType.isMesh) {
        objectType.rotation.z = -angle;

        objectType.material.map = Cache.get(
          objectType.name +
            ((this.animCounter % this.frameCounts[objectType.name]) + 1)
        );
        objectType.material.emissiveMap = objectType.material.map;
        objectType.material.map.minFilter = NearestFilter;
        objectType.material.map.magFilter = NearestFilter;
        objectType.material.needsUpdate = true;
      }
    });
  }

  initCrowd() {
    this.mesh.traverse((objectType) => {
      if (objectType.isMesh) {
        objectType.visible = false;
        objectType.material.emissiveIntensity = 0;
      }
    });
  }

  setLevel(level) {
    level = parseInt(level, 10);
    switch (level) {
      case 1:
        this.initCrowd();
        break;
      case 3:
        //Digga D
        this.mesh.getObjectByName('diggad').visible = true;
        break;
      case 4:
        this.mesh.getObjectByName('phone').visible = true;
        break;
      case 6:
        this.mesh.getObjectByName('ball').visible = true;
        break;
      case 8:
        this.mesh.getObjectByName('hairgirl').visible = true;
        break;
      case 10:
        this.mesh.traverse((objectType) => {
          if (objectType.isMesh) {
            objectType.material.emissiveIntensity = 0.7;
          }
        });
        break;
      case 11:
        //T-Pain
        this.mesh.getObjectByName('tpain').visible = true;
        break;
      case 12:
        this.mesh.getObjectByName('nonchalant').visible = true;
        break;
      case 13:
        //Kehlani
        this.mesh.getObjectByName('kehlani').visible = true;
        break;
      case 14:
        //Sahbabi & Millie
        this.mesh.getObjectByName('sahbabi').visible = true;
        this.mesh.getObjectByName('millie').visible = true;
        break;
      case 15:
        //MoStack
        this.mesh.getObjectByName('mostack').visible = true;
        break;
      case 16:
        //Mabel
        this.mesh.getObjectByName('mabel').visible = true;
        break;

      default:
        break;
    }
  }

  setSceneEmissive(emissiveLevel) {
    this.mesh.traverse((objectType) => {
      if (objectType.isMesh) {
        const existingMap = objectType.material.map;
        if (existingMap != null) {
          objectType.material.emissiveIntensity = emissiveLevel;
          objectType.material.needsUpdate = true;
        }
      }
    });
  }
}
