/**
 * We can store all cache in this one object with an id, then we can request it from the cache back.
 * This way we can request resour
 */
export const Cache = Object.create(null);

export function getResource(id) {
  if (Cache[id]) {
    return Cache[id].resource || Cache[id].data || Cache[id];
  }

  return null;
}

export function getResourceClone(id) {
  if (Cache[id]) {
    const empty = {};
    const returnedTarget = Object.assign(Cache[id], empty);

    return returnedTarget;
  }

  return null;
}

export function setResource(id, value) {
  if (!Cache[id]) {
    Cache[id] = value;
  }
}

export const Animation = Object.create(null);

export function getAnimationFrames(name = null) {
  if (!Animation[name]) {
    const frames = [];

    for (const id in Cache) {
      if (typeof Cache[id] !== 'function') {
        if (id.indexOf(name) > -1) {
          frames.push(id);
        }
      }
    }
    frames.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      return 0;
    });
    Animation[name] = frames;
  }

  return Animation[name];
}

Cache.get = getResource;
Cache.getClone = getResource;
Cache.set = setResource;
Cache.getAnimationFrames = getAnimationFrames;
