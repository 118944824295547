import { useEffect } from 'react';
import sono from 'sono';

import instructionsVOAsset from '../../assets/sfx/vo/intro.mp3';

import level1Asset from '../../assets/sfx/vo/thatll-do.mp3';
import level2Asset from '../../assets/sfx/vo/pick-it-up-x3.mp3';
import level3Asset from '../../assets/sfx/vo/good-game.mp3';
import level4Asset from '../../assets/sfx/vo/not-bad.mp3';
import level5Asset from '../../assets/sfx/vo/nuttin-but-net.mp3';
import level6Asset from '../../assets/sfx/vo/thats-not-even-fair.mp3';
import level7Asset from '../../assets/sfx/vo/doing-great.mp3';
import level8Asset from '../../assets/sfx/vo/yeah-cold.mp3';
import level9Asset from '../../assets/sfx/vo/eskeddit.mp3';
import level10Asset from '../../assets/sfx/vo/jheeeze.mp3';
import level11Asset from '../../assets/sfx/vo/from-downtown.mp3';
import level12Asset from '../../assets/sfx/vo/hustle-come-on.mp3';
import level13Asset from '../../assets/sfx/vo/numbers-like-that.mp3';
import level14Asset from '../../assets/sfx/vo/dont-give-up.mp3';
import level15Asset from '../../assets/sfx/vo/is-that-how-youre-coming.mp3';
import level16Asset from '../../assets/sfx/vo/im-tryna-be-like-you-bro.mp3';

export const SFXLoading = () => {
  useEffect(() => {
    sono.load({
      url: [
        // Instructions VO
        {
          id: 'instructionsVOAsset',
          url: instructionsVOAsset,
        },

        // Level transitions VO
        {
          id: 'level1Asset',
          url: level1Asset,
        },
        {
          id: 'level2Asset',
          url: level2Asset,
        },
        {
          id: 'level3Asset',
          url: level3Asset,
        },
        {
          id: 'level4Asset',
          url: level4Asset,
        },
        {
          id: 'level5Asset',
          url: level5Asset,
        },
        {
          id: 'level6Asset',
          url: level6Asset,
        },
        {
          id: 'level7Asset',
          url: level7Asset,
        },
        {
          id: 'level8Asset',
          url: level8Asset,
        },
        {
          id: 'level9Asset',
          url: level9Asset,
        },
        {
          id: 'level10Asset',
          url: level10Asset,
        },
        {
          id: 'level11Asset',
          url: level11Asset,
        },
        {
          id: 'level12Asset',
          url: level12Asset,
        },
        {
          id: 'level13Asset',
          url: level13Asset,
        },
        {
          id: 'level14Asset',
          url: level14Asset,
        },
        {
          id: 'level15Asset',
          url: level15Asset,
        },
        {
          id: 'level16Asset',
          url: level16Asset,
        },
      ],
    });
  }, []);

  return null;
};
