import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader';
import { PostProcessingShader } from './shaders/postProcessingShader';
import { Renderer, CURRENT_SCENE, Camera } from '../globals/constants';

class PostProcessing {
  constructor() {
    this.composer = new EffectComposer(Renderer);
    this.renderPass = new RenderPass(CURRENT_SCENE, Camera);
    this.effectCopy = new ShaderPass(CopyShader);
    this.postProcessingShader = new ShaderPass(PostProcessingShader);
    this.effectCopy.renderToScreen = true;

    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.postProcessingShader);
    this.composer.addPass(this.effectCopy);
  }

  setBlackLevel(blackLevel) {
    this.postProcessingShader.uniforms.uBlackMix.value = blackLevel;
  }

  render() {
    this.composer.render();
  }
}

export default PostProcessing;
