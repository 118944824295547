import styled from 'styled-components';

import { Score } from './components/Score/Score';
import { Level } from './components/Level/Level';
import { GameCountdownTimer } from './components/GameCountdownTimer/GameCountdownTimer';
import { TogglePlayer } from './components/TogglePlayer/TogglePlayer';
import { SpotifyPlayer } from './components/SpotifyPlayer/SpotifyPlayer';

import bkgAsset from '../../assets/images/header-bkg.png';
import { minMaxSizing } from '../../utils/typeStyling';

const Styles = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  height: 55px;

  background-image: url(${bkgAsset});
  background-position: center center;
  background-size: cover;
  z-index: 2;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 620px;
    margin: 0 auto;

    height: 55px;
  }

  .content-block {
    display: flex;
    align-items: center;
  }

  .left-block {
    ${minMaxSizing({ css: 'width', min: 71, max: 250 })};
    justify-content: flex-start;
  }

  .centre-block {
    ${minMaxSizing({ css: 'width', min: 160, max: 700 })};
  }

  .right-block {
    ${minMaxSizing({ css: 'width', min: 71, max: 250 })};
    justify-content: flex-end;
  }
`;

export const Header = () => (
  <>
    <Styles as="header">
      <div className="content">
        <div className="content-block left-block">
          <Score />
          <TogglePlayer />
        </div>

        <div className="content-block centre-block">
          <Level />
        </div>

        <div className="content-block right-block">
          <GameCountdownTimer />
        </div>
      </div>
    </Styles>

    <SpotifyPlayer />
  </>
);
