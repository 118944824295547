import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tween, Timeline } from 'react-gsap';

import { StartTimer } from './components/StartTimer/StartTimer';
import { TransitionTitle } from './components/TransitionTitle/TransitionTitle';
import { TransitionHead } from './components/TransitionHead/TransitionHead';

import { GAME } from '../../common/constants/game';
import { selectGameStatus, selectLevel } from '../../store/slices/gameSlice';

import { useLevelData } from '../../hooks/useLevelData';
import { useTransitionAssets } from '../../hooks/useTransitionAssets';
import { RESPONSIVE } from '../../common/constants/responsive';

export const GameUI = () => {
  const level = useSelector(selectLevel);
  const gameStatus = useSelector(selectGameStatus);
  const [levelData] = useLevelData(level);

  const [
    transitionAudio,
    titlePlayState,
    headPlayState,
    startAnimations,
  ] = useTransitionAssets(levelData, level);

  useEffect(() => {
    if (gameStatus === GAME.PAUSED) {
      startAnimations();
    }
  }, [gameStatus, startAnimations]);

  useEffect(() => {
    if (gameStatus === GAME.PAUSED) {
      setTimeout(() => {
        transitionAudio?.play();
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatus]);

  return (
    <>
      <StartTimer />

      {gameStatus === GAME.PAUSED && (
        <>
          <Timeline
            target={
              <TransitionTitle
                src={levelData?.transitions?.title?.asset}
                minwidth={levelData?.transitions?.title?.minWidth}
                maxwidth={levelData?.transitions?.title?.maxWidth}
                alt={level}
                draggable="false"
              />
            }
            playState={titlePlayState}
          >
            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.1} />
            <Tween
              from={{ css: { width: '0px' } }}
              to={{
                css: {
                  width: ` calc(
                  ${levelData?.transitions?.title?.minWidth}px +
                    (${levelData?.transitions?.title?.maxWidth} - ${levelData?.transitions?.title?.minWidth}) *
                    (
                      (100vw - ${RESPONSIVE.MIN_DEFAULT}px) /
                        (${RESPONSIVE.MAX_DEFAULT} - ${RESPONSIVE.MIN_DEFAULT})
                    )`,
                },
              }}
              duration={0.25}
            />
          </Timeline>

          <Timeline
            target={
              <TransitionHead
                src={levelData?.transitions?.head?.asset}
                minwidth={levelData?.transitions?.head?.minWidth}
                maxwidth={levelData?.transitions?.head?.maxWidth}
                alt={level}
                draggable="false"
              />
            }
            playState={headPlayState}
          >
            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0} />
          </Timeline>
        </>
      )}
    </>
  );
};
