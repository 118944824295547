import {
  MeshBasicMaterial,
  NearestFilter,
  LinearEncoding,
  MultiplyBlending,
} from 'three';
import { CURRENT_SCENE } from '../globals/constants';
import { Cache } from '../utils/cache';
export default class Geese {
  constructor() {
    this.glb = Cache.get('geese');
    this.textureArray = [
      Cache.get('geese_01'),
      Cache.get('geese_02'),
      Cache.get('geese_03'),
    ];
    this.phaseIndexArray = [];
    this.speed = 3;
    this.framerate = 6;
    this.cumulativeTime = 0;
    for (let i = 0; i < 10; i++) {
      let phase = Math.floor(3 * Math.random());
      this.phaseIndexArray.push(phase);
      let gooseMesh = this.glb.scene.children[i];
      gooseMesh.material = new MeshBasicMaterial();
      gooseMesh.material.transparent = true;
      gooseMesh.material.alphaTest = 0.5;
      gooseMesh.material.map = this.textureArray[phase];
      gooseMesh.material.map.minFilter = NearestFilter;
      gooseMesh.material.map.magFilter = NearestFilter;
      gooseMesh.material.map.encoding = LinearEncoding;
      gooseMesh.material.fog = false;
      gooseMesh.material.needsUpdate = true;
    }

    //this.glb.scene.position.set(220, 0, 25);
    this.glb.scene.position.set(250, -30, 70);

    CURRENT_SCENE.add(this.glb.scene);
  }

  update(deltaSeconds) {
    if (this.active) {
      this.cumulativeTime += deltaSeconds;

      this.glb.scene.position.x += this.speed * deltaSeconds;

      if (this.cumulativeTime > 1 / this.framerate) {
        for (let i = 0; i < 10; i++) {
          this.phaseIndexArray[i] = (this.phaseIndexArray[i] + 1) % 3;
          const phase = this.phaseIndexArray[i];
          let gooseMesh = this.glb.scene.children[i];
          gooseMesh.material.map = this.textureArray[phase];
          gooseMesh.material.needsUpdate = true;
        }
        this.cumulativeTime = 0;
      }
    }
  }

  initFlock() {
    this.active = false;
    this.glb.scene.visible = false;
    this.cumulativeTime = 0;
  }

  setLevel(level) {
    level = parseInt(level, 10);
    switch (level) {
      case 1:
        this.initFlock();
        break;
      case 4:
        this.active = true;
        this.glb.scene.visible = true;
        this.glb.scene.position.set(250, -30, 70);
        break;
      case 5:
        this.initFlock();
        break;

      default:
        break;
    }
  }
}
