import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { selectSfxStatus, toggleSfx } from '../../../../store/slices/appSlice';
import { BREAKPOINTS } from '../../../../common/constants/responsive';
import { trackEvent } from '../../../../utils/trackEvent';
import Broadcaster from '../../../../utils/Broadcaster';
import { EVENT } from '../../../../common/constants/events';
import { ACTION, CATEGORY } from '../../../../common/constants/tracking';

const Styles = styled.button`
  position: relative;
  color: white;
  border: 0;
  padding: 0;
  margin-bottom: 0.5em;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }

  .sfx-status {
    color: #ec5535;
  }
`;

export const SFXButton = () => {
  const SFX = useSelector(selectSfxStatus);
  const dispatch = useDispatch();

  return (
    <Styles
      as="button"
      type="button"
      onClick={() => {
        if (SFX) {
          trackEvent(CATEGORY.FOOTER, ACTION.CLICK, 'disable-sfx');
        } else {
          trackEvent(CATEGORY.FOOTER, ACTION.CLICK, 'enable-sfx');
        }
        Broadcaster.emit(EVENT.TOGGLE_SFX);
        dispatch(toggleSfx());
      }}
    >
      SFX: <span className="sfx-status">{SFX ? 'ON' : 'OFF'}</span>
    </Styles>
  );
};
