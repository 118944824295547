export const copyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.append(textArea);

  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch {
    console.log('Could not copy to clipboard');
  }

  textArea.remove();
};
