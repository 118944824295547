import { CURRENT_SCENE } from '../../globals/constants';
import { Constraint } from 'cannon';

const PostProcessingShader = {
  uniforms: {
    tDiffuse: { value: null },
    uBlackMix: { value: 0 },
  },

  vertexShader: `
    varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,

  fragmentShader: `
    #include <common>
    uniform sampler2D tDiffuse;
    uniform float uBlackMix;
    varying vec2 vUv;
    
    float map(float value, float min1, float max1, float min2, float max2){
      float perc = (value - min1) / (max1 - min1);
      return perc * (max2 - min2) + min2;
    }

		void main() {
      vec3 colorOut = texture2D( tDiffuse, vUv ).rgb;
      
      //Vignette
      float offset = 1.3;
      float darkness = 0.3;
			vec2 uv = ( vUv - vec2( 0.5 ) ) * vec2( offset );
      colorOut = mix( colorOut, mix(colorOut, vec3(0.), darkness), dot( uv, uv ) );

      //Grade
      colorOut.g = map(colorOut.g, 0., 1., 0.01, 0.99);
      colorOut.b = map(colorOut.b, 0., 1., 0.03, 0.95);

			gl_FragColor = vec4(mix(colorOut, vec3(0.), uBlackMix), 1.);
    }
    `,
};

export { PostProcessingShader };
