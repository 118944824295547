export const STAGE = {
  INTRO: 'INTRO',
  INSTRUCTIONS: 'INSTRUCTIONS',
  LOADING: 'LOADING',
  SPOTIFY_INSTRUCTIONS: 'SPOTIFY_INSTRUCTIONS',
  GAME: 'GAME',
  GAME_OVER: 'GAME_OVER',
};

export const DEFAULTSTAGE = STAGE.INTRO;
