export const downloadURI = (uri, name) => {
  const anchor = document.createElement('a');

  anchor.setAttribute('download', name);
  anchor.href = uri;

  document.body.append(anchor);

  anchor.click();
  anchor.remove();
};
