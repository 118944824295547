import { Color, Mesh, PlaneGeometry, ShaderMaterial } from 'three';
import {
  CURRENT_SCENE,
  DEBUG_WINDOWS,
  timeOfDay,
} from '../../globals/constants';

export default class WindowShader {
  constructor(texture, envMap) {
    const uniforms = {
      uEnvMap: { value: envMap },
      uColor: { value: new Color(0xff0000) },
      uTimeOfDay: { value: timeOfDay },
    };

    const vertexShader = `
    void main() {
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `;

    const fragmentShader = `
      #ifndef OPTI
        //uniform vec3 uColor;
        uniform vec3 uColor;
        uniform float uTimeOfDay;
      #endif
      void main() {
        gl_FragColor = vec4(uColor.r, uColor.g, uTimeOfDay, 1.0);
      }`;

    this.material = new ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
    });
  }
}
