export const GAME = {
  PLAYING: 'PLAYING',
  READY: 'READY',
  TRANSITION: 'TRANSITION',
  COUNTINGDOWN: 'COUNTINGDOWN',
  PAUSED: 'PAUSED',
  OVER: 'OVER',
  WON: 'WON',
  SCORE_DELAY: 1000,
};
