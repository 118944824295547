import { WebGLRenderer } from 'three';
import { setRenderer, SIZES } from '../globals/constants';

export default class Renderer extends WebGLRenderer {
  constructor(parameters) {
    super(parameters);
    setRenderer(this);
    this.setClearColor(0xa0a0a0);
  }

  resize() {
    this.setSize(SIZES.width, SIZES.height);
    this.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }
}
