import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { LEVEL } from '../../../../common/constants/levels';
import { selectLevel } from '../../../../store/slices/gameSlice';
import { minMaxSizing } from '../../../../utils/typeStyling';

const Styles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  position: relative;
  font-size: 20px;
  color: white;
  text-align: center;
  margin: 0 auto;

  .level-details {
    transform: translateY(-17.5%);
    padding: 0 16px;
  }

  p {
    ${minMaxSizing({ css: 'font-size', min: 14, max: 21 })};
    width: 100%;
    margin: 0;
    line-height: 0.8;

    text-transform: uppercase;
  }
`;

export const Level = () => {
  const level = useSelector(selectLevel);

  const [levelName, setLevelName] = useState('');

  useEffect(() => {
    setLevelName(LEVEL[level]?.name);
  }, [level]);

  return (
    <Styles className="bitmap-pro">
      <div className="level-details">
        <p>Level {level}</p>
        {levelName && <p>{levelName}</p>}
      </div>
    </Styles>
  );
};
