import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectShowSpotifyPlayer,
  toggleSpotifyPlayer,
} from '../../../../store/slices/appSlice';

import cassetteOrangeAsset from '../../../../assets/images/cassette-orange-bitmap.png';
import cassetteWhiteAsset from '../../../../assets/images/cassette-white-bitmap.png';
import { trackEvent } from '../../../../utils/trackEvent';
import { ACTION, CATEGORY } from '../../../../common/constants/tracking';

const CassetteButton = styled.div`
  width: 41.25px;
  height: 30px;

  background-image: ${(props) =>
    props.showSpotifyPlayer
      ? `url(${cassetteOrangeAsset})}`
      : `url(${cassetteWhiteAsset})}`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  border: none;
  padding: 0;
`;

export const TogglePlayer = () => {
  const showSpotifyPlayer = useSelector(selectShowSpotifyPlayer);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleSpotifyPlayer());

    if (showSpotifyPlayer) {
      trackEvent(CATEGORY.GAME, ACTION.CLICK, 'hide-spotify-embed');
    } else {
      trackEvent(CATEGORY.GAME, ACTION.CLICK, 'show-spotify-embed');
    }
  };

  return (
    <CassetteButton
      as="button"
      type="button"
      showSpotifyPlayer={showSpotifyPlayer}
      onClick={() => {
        handleToggle();
      }}
    />
  );
};
