import sono from 'sono';
import { EVENT } from '../../common/constants/events';
import OPPONENT_TYPES from '../../common/constants/opponentTypes';
import Broadcaster from '../../utils/Broadcaster';

export default class SoundHandler {
  constructor() {
    this.enabled = true;
    this.toggledOn = true;
    Broadcaster.on(EVENT.TOGGLE_SFX, () => {
      this.toggledOn = !this.toggledOn;
    });
  }

  load() {
    return new Promise((resolve) => {
      sono.load({
        url: [
          {
            id: 'basicgirl',
            url: '/audio/gameplay/basicgirl.mp3',
          },
          {
            id: 'dog',
            url: '/audio/gameplay/dog.mp3',
          },
          {
            id: 'pig',
            url: '/audio/gameplay/pig.mp3',
          },
          {
            id: 'poshboy0',
            url: '/audio/gameplay/posh_goodness.mp3',
          },
          {
            id: 'poshboy1',
            url: '/audio/gameplay/posh_gosh.mp3',
          },
          {
            id: 'scootergirl',
            url: '/audio/gameplay/scootergirl.mp3',
          },
          {
            id: 'bounce_hi_high',
            url: '/audio/gameplay/bounce_hi.mp3',
          },
          {
            id: 'bounce_hi_mid',
            url: '/audio/gameplay/bounce_hi_mid.mp3',
          },
          {
            id: 'bounce_hi_low',
            url: '/audio/gameplay/bounce_hi_soft.mp3',
          },
          {
            id: 'backboard_a',
            url: '/audio/gameplay/backboard_a.mp3',
          },
          {
            id: 'backboard_b',
            url: '/audio/gameplay/backboard_b.mp3',
          },
          {
            id: 'hoop_a',
            url: '/audio/gameplay/hoop_a.mp3',
          },
          {
            id: 'horn',
            url: '/audio/gameplay/gamestart_horn.mp3',
          },
        ],
        onComplete: resolve(),
      });
    });
  }

  playSound(id, volume) {
    if (!this.enabled || !this.toggledOn) return;
    const sound = sono.get(id);
    sound.volume = volume;
    sono.play(id);
  }

  playOpponent(opponentType) {
    if (!this.enabled || !this.toggledOn) return;
    if (opponentType === OPPONENT_TYPES.POSH_BOY) {
      opponentType = `${opponentType}${Math.round(Math.random())}`;
    }
    sono.play(opponentType);
  }
}
