import { useDispatch } from 'react-redux';
import { Timeline, Tween } from 'react-gsap';

import { useEffect, useState } from 'react';
import sono from 'sono';
import { AJHead } from './components/AJHead/AJHead';
import { FluGame } from './components/FluGame/FluGame';
import { TheGame } from './components/TheGame/TheGame';
import { UIBackground } from '../../components/UIBackground/UIBackground';
import { SpotifyText } from './components/SpotifyText/SpotifyText';

import fluGameAsset from '../../assets/images/flu-game-bitmap.png';
import theGameAsset from '../../assets/images/the-game-bitmap.png';
import fireballAsset from '../../assets/images/gifs/fireball.gif';
import spotifyTextAsset from '../../assets/images/Spotify presents.svg';
import ajAudioAsset from '../../assets/sfx/vo/alright-boom.mp3';
import flamingBasketballsAsset from '../../assets/sfx/flaming-basketballs.mp3';

import { STAGE } from '../../common/constants/stages';
import { setStage } from '../../store/slices/appSlice';
import { ACTION, CATEGORY } from '../../common/constants/tracking';
import { FlamingBall } from './components/FlamingBall/FlamingBall';
import { trackEvent } from '../../utils/trackEvent';
import { ContentStyles, StartButton } from './ContentStyles';

export const IntroUI = () => {
  const dispatch = useDispatch();

  const [ajHeadPlaying, setAjHeadPlaying] = useState(false);

  const fireballAniDuration = 2;

  const ajAudio = sono.create(ajAudioAsset).once('ended', () => {
    setAjHeadPlaying(false);
  });

  const flamingBasketballsAudio = sono
    .create(flamingBasketballsAsset)
    .once('ended', () => {
      setAjHeadPlaying(true);
      ajAudio?.play();
    });

  useEffect(() => {
    flamingBasketballsAudio?.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UIBackground minHeight>
      <ContentStyles>
        <SpotifyText src={spotifyTextAsset} className="spotify-presents" />

        <div className="main-block">
          <Timeline
            target={
              <FlamingBall src={fireballAsset} className="fireball-left" />
            }
          >
            <Tween
              from={{ y: '-100vh' }}
              to={{
                y: '0vh',
              }}
              duration={fireballAniDuration}
            />
          </Timeline>

          <div className="centre-block">
            <AJHead alt="AJ Tracey" draggable="false" playing={ajHeadPlaying} />

            <FluGame src={fluGameAsset} draggable="false" />
            <TheGame src={theGameAsset} draggable="false" />
          </div>

          <Timeline
            target={
              <FlamingBall src={fireballAsset} className="fireball-right" />
            }
          >
            <Tween
              from={{ y: '-100vh' }}
              to={{
                y: '0vh',
              }}
              duration={fireballAniDuration}
            />
          </Timeline>
        </div>

        <Timeline
          target={
            <div>
              <StartButton
                text="Start"
                className="start-button"
                onClick={() => {
                  flamingBasketballsAudio.stop();
                  ajAudio.stop();
                  trackEvent(CATEGORY.INTRO, ACTION.CLICK, 'start-game');
                  dispatch(setStage(STAGE.LOADING));
                }}
              />
            </div>
          }
          // playing={startButtonPlaying}
        >
          <Tween
            from={{ css: { opacity: 0 } }}
            to={{ css: { opacity: 1 } }}
            duration={2}
          />
        </Timeline>
      </ContentStyles>
    </UIBackground>
  );
};
