import { setGUI } from './constants';

const InitialiseGui = () => {
  const dat = require('dat.gui');
  const gui = new dat.GUI();
  const el = document.querySelectorAll('.dg.ac')[0];
  el.style.zIndex = 200;
  gui.hide();
  gui.close();
  setGUI(gui);
};

export default InitialiseGui;
