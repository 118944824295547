import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Broadcaster from '../../../../utils/Broadcaster';
import { selectLevel, selectScore } from '../../../../store/slices/gameSlice';

import { useLevelData } from '../../../../hooks/useLevelData';
import { EVENT } from '../../../../common/constants/events';

export const Score = () => {
  const score = useSelector(selectScore);
  const level = useSelector(selectLevel);

  const dispatch = useDispatch();
  const [levelData, levelsCount] = useLevelData(level);

  useEffect(() => {
    if (score === levelData?.goal) {
      Broadcaster.emit(EVENT.GOAL_REACHED);
    }
  }, [dispatch, score, levelData, levelsCount]);

  return null;
};
