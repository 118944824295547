import * as Comlink from 'comlink';
import { Vector3, Vector4 } from 'three';

/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!./worker';
import {
  basketball,
  Camera,
  config,
  GUI,
  SoundHandler,
} from '../../globals/constants';

export default class Physics {
  constructor() {
    // Make the elements and start the worker
    this.worker = new Worker();
    this.api = Comlink.wrap(this.worker);

    this.defaultConfig = {
      velocity: 5.2,
      elevation: 35,
    };

    /** late nigth note:
     * velocity between 0 and 1, which will be the multiplier for the angle.
     * elevation is degrees, which idk how that's going to work really
     * we don't rotate the ball... so it would have to be in the impulse.
     */

    GUI.add(this.defaultConfig, 'velocity').min(0).max(2).step(0.01);
    GUI.add(this.defaultConfig, 'elevation').min(0).max(50).step(0.01);
  }

  async updateWorld(delta) {
    await this.api
      .renderScene({
        dt: delta,
      })
      .then(({ position, quaternion, bounceSound }) => {
        const { shouldPlay, hasBeenTriggered, id, volume } = bounceSound;
        if (shouldPlay && !hasBeenTriggered && id && volume) {
          SoundHandler.playSound(id, volume);
        }
        if (position) {
          basketball.position.copy(position);
          basketball.quaternion.copy(quaternion);
        }
      });
  }

  async shootBall(ball) {
    const direction = new Vector3();
    const currentBallVectors =
      config.ball && config.ball.velocity && config.ball.elevation
        ? config.ball
        : this.defaultConfig;
    ball.keepInFrontCamera();
    Camera.getWorldDirection(direction);

    await this.api.shootBall({
      direction,
      ballPosition: ball.position,
      velocity: currentBallVectors.velocity,
      elevation: currentBallVectors.elevation,
      ballQuaternion: new Vector4(
        ball.quaternion.x,
        ball.quaternion.y,
        ball.quaternion.z + ball.baseRotation,
        ball.quaternion.w
      ),
    });
  }
}
