import styled from 'styled-components';

// Sanitize.css
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

// Fonts
import './fonts.css';

export const GlobalStyles = styled.div`
  .bitmap-pro {
    font-family: 'Bitmap Pro';
    letter-spacing: 0.05em;
  }

  /* Global styles */
  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  * {
    outline: none;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    image-rendering: pixelated;
  }

  * {
    image-rendering: pixelated;
  }

  button {
    cursor: pointer;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  a.focus-visible,
  button.focus-visible {
    outline: 0.1875rem solid rgba(21, 156, 228, 0.4);
  }
`;
