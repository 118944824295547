import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UIBackground } from '../../components/UIBackground/UIBackground';
import { GameOverMate } from './components/GameOverMate/GameOverMate';
import { CTA } from '../../components/CTA/CTA';
import { CopyUrl } from '../../components/CopyUrl/CopyUrl';
import { Scorecard } from '../../components/Scorecard/Scorecard';
import { ScorecardDownload } from '../../components/Scorecard/components/Download/Download';

import gameOverMateAsset from '../../assets/images/game-over-mate-bitmap.png';
import mvpAsset from '../../assets/images/mvp-of-rac.png';

import { selectGameStatus, selectLevel } from '../../store/slices/gameSlice';
import { GRADES } from '../../common/constants/grades';
import { EVENT } from '../../common/constants/events';
import Broadcaster from '../../utils/Broadcaster';
import { MVP } from './components/MVP/MVP';
import { GAME } from '../../common/constants/game';
import { ACTION, CATEGORY } from '../../common/constants/tracking';
import { trackEvent } from '../../utils/trackEvent';

const Content = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 55px 16px 120px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;

  .game-over-mate,
  .mvp {
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .copy-url {
    margin-bottom: 40px;
    margin-top: 20px;
  }
`;

export const GameOverUI = () => {
  const gameStatus = useSelector(selectGameStatus);
  const level = useSelector(selectLevel);

  const [grade, setGrade] = useState(null);

  const [scorecardAsset, setScorecardAsset] = useState(null);
  const [scorecardDownloadAsset, setScorecardDownloadAsset] = useState(null);

  useEffect(() => {
    trackEvent(CATEGORY.OUTRO, ACTION.STATE, `game-over-grade-${grade}`);

    setScorecardAsset(`${process.env.PUBLIC_URL}/scorecard/${grade}.gif`);
    setScorecardDownloadAsset(
      `${process.env.PUBLIC_URL}/scorecard/${grade}.mp4`
    );
  }, [gameStatus, level, grade]);

  useEffect(() => {
    if (level === 16 && gameStatus === GAME.WON) {
      return setGrade(GRADES.A);
    }

    if (gameStatus !== GAME.WON) {
      switch (level) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return setGrade(GRADES.E);
        case 6:
        case 7:
        case 8:
        case 9:
          return setGrade(GRADES.D);
        case 10:
        case 11:
        case 12:
        case 13:
          return setGrade(GRADES.C);
        case 14:
        case 15:
        case 16:
          return setGrade(GRADES.B);
        default:
          return null;
      }
    }
  }, [gameStatus, level]);

  return (
    <UIBackground minHeight>
      <Content>
        {gameStatus === GAME.WON ? (
          <MVP src={mvpAsset} className="mvp" draggable="false" />
        ) : (
          <GameOverMate
            src={gameOverMateAsset}
            className="game-over-mate"
            draggable="false"
          />
        )}

        <CTA
          text="Play again"
          onClick={() => {
            trackEvent(CATEGORY.OUTRO, ACTION.CLICK, 'play-again');
            Broadcaster.emit(EVENT.GAME_RESET);
          }}
        />

        {grade && (
          <>
            <Scorecard asset={scorecardAsset} grade={grade} />
            <ScorecardDownload asset={scorecardDownloadAsset} grade={grade} />
          </>
        )}

        <div className="copy-url">
          <CopyUrl />
        </div>

        <CTA
          as="a"
          href="http://open.spotify.com/album/2mgAD4bdEfvmwG0iq8K0h7"
          text="Listen to the full album"
        />
      </Content>
    </UIBackground>
  );
};
