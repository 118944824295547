import { CURRENT_SCENE } from '../globals/constants';
import { Cache } from '../utils/cache';
import SkydomeShader from '../3D/shaders/skydomeShader';
import { Color, MathUtils } from 'three';

export default class Sky {
  constructor() {
    this.horizonStops = [0, 0, 0, 0];
    this.midStops = [0.15, 0.2, 0.153, 0.068];
    this.zenithStops = [1, 0.8, 1, 0.5];
    this.horizonColors = [
      new Color(0xb9b9b9),
      new Color(0xda7e6d),
      new Color(0x783f28),
      new Color(0x785b42),
    ];
    this.midColors = [
      new Color(0xdfd2bf),
      new Color(0x7377a2),
      new Color(0x47283c),
      new Color(0x312727),
    ];
    this.zenithColors = [
      new Color(0x575757),
      new Color(0x363840),
      new Color(0x00031a),
      new Color(0x05090f),
    ];

    this.horizonMix = new Color();
    this.midMix = new Color();
    this.zenithMix = new Color();

    const glb = Cache.get('skydome'); // This gets the main glb file from the cache.
    this.mesh = glb.scene.getObjectByName('Sky_Dome');
    this.skydomeShader = new SkydomeShader();
    this.mesh.material = this.skydomeShader.material;

    // Get current scene (there's only one but just in case we needed one more for intro/outro)
    CURRENT_SCENE.add(this.mesh);
  }

  setTime(timeOfDay) {
    const scaledTimeOfDay = timeOfDay * 3;
    const mixFactor = scaledTimeOfDay % 1;
    const mixFrom = Math.floor(scaledTimeOfDay);
    const mixTo = Math.ceil(scaledTimeOfDay);

    const horizonStopMix = MathUtils.lerp(
      this.horizonStops[mixFrom],
      this.horizonStops[mixTo],
      mixFactor
    );
    const midStopMix = MathUtils.lerp(
      this.midStops[mixFrom],
      this.midStops[mixTo],
      mixFactor
    );
    const zenithStopMix = MathUtils.lerp(
      this.zenithStops[mixFrom],
      this.zenithStops[mixTo],
      mixFactor
    );

    this.horizonMix.lerpColors(
      this.horizonColors[mixFrom],
      this.horizonColors[mixTo],
      mixFactor
    );
    this.midMix.lerpColors(
      this.midColors[mixFrom],
      this.midColors[mixTo],
      mixFactor
    );
    this.zenithMix.lerpColors(
      this.zenithColors[mixFrom],
      this.zenithColors[mixTo],
      mixFactor
    );

    this.skydomeShader.material.uniforms.uHorizonStopMix.value = horizonStopMix;
    this.skydomeShader.material.uniforms.uMidStopMix.value = midStopMix;
    this.skydomeShader.material.uniforms.uZenithStopMix.value = zenithStopMix;

    this.skydomeShader.material.uniforms.uHorizonMix.value = this.horizonMix;
    this.skydomeShader.material.uniforms.uMidMix.value = this.midMix;
    this.skydomeShader.material.uniforms.uZenithMix.value = this.zenithMix;
  }
}
