import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../common/constants/responsive';
import { minMaxSizing } from '../../utils/typeStyling';

const Styles = styled.div`
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  ${minMaxSizing({ css: 'margin-top', min: 24, max: 46 })};

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    width: 240px;
  }

  @media (min-width: ${BREAKPOINTS.MEDIUM}) {
    width: 300px;
  }
`;

const Img = styled.img`
  display: block;
  width: 100%;
`;

export const Scorecard = ({ asset, grade }) => (
  <Styles>
    {grade && <Img src={asset} alt={`Grade: ${grade.toUpperCase()}`} />}
  </Styles>
);

Scorecard.propTypes = {
  asset: PropTypes.string,
  grade: PropTypes.string,
};
