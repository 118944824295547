import styled from 'styled-components';

export const TransitionTitle = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;

  /* width: 0; */

  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  transition: linear 0.2s;
`;
