import { NearestFilter, Color } from 'three';
import CloudsShader from '../3D/shaders/cloudsShader';
import { CURRENT_SCENE } from '../globals/constants';
import { Cache } from '../utils/cache';

export default class Cloud {
  constructor() {
    this.SunColors = [
      new Color(0x665e52),
      new Color(0xd57e68),
      new Color(0x802e1a),
      new Color(0x806a46),
    ];
    this.sunColor = new Color();

    const glb = Cache.get('clouds'); // This gets the main glb file from the cache.
    this.mesh = glb.scene.getObjectByName('Sky_Clouds');

    this.texture = Cache.get('cloudsTexture');
    this.texture.minFilter = NearestFilter;
    this.texture.magFilter = NearestFilter;
    this.cloudsShader = new CloudsShader(this.texture);
    this.mesh.material = this.cloudsShader.material;
    this.mesh.material.transparent = true;

    // Get current scene (there's only one but just in case we needed one more for intro/outro)
    CURRENT_SCENE.add(this.mesh);
  }

  setTime(timeOfDay) {
    const scaledTimeOfDay = timeOfDay * 3;
    const mixFactor = scaledTimeOfDay % 1;
    const mixFrom = Math.floor(scaledTimeOfDay);
    const mixTo = Math.ceil(scaledTimeOfDay);

    this.sunColor.lerpColors(
      this.SunColors[mixFrom],
      this.SunColors[mixTo],
      mixFactor
    );

    this.cloudsShader.material.uniforms.uSunColor.value = this.sunColor;
    this.cloudsShader.material.uniforms.uTimeOfDay.value = timeOfDay;
  }
}
