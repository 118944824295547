import { Clock, Scene, FogExp2, Color } from 'three';
import Camera from './3D/camera';
import Renderer from './3D/renderer';
import Basketball from './3D/physics/basketball';
import {
  setBasketball,
  setConfig,
  setCurrentScene,
  setDeltaTime,
  setRenderer,
  setSizes,
  setLevel,
  setElapsedTime,
  setTimeOfDay,
  timeOfDay,
  setSoundHandler,
  setAllowScoring,
} from './globals/constants';
import InitialiseGui from './globals/gui';
import Broadcaster from '../utils/Broadcaster';

import Loader from './3D/loader';
import manifest from './manifest';
import Lights from './3D/lights';
import Physics from './3D/physics';
import PostProcessing from './3D/postProcessing';
import Opponents from './3D/opponents';
import { EVENT } from '../common/constants/events';
import WindowShader from './3D/shaders/windowShader';
import Ladbroke from './objects/ladbroke';
import Crowd from './objects/crowd';
import Geese from './objects/geese';
import Sky from './objects/sky';
import Cloud from './objects/cloud';
import Ringcasters from './objects/ringcasters';
import { LEVEL } from '../common/constants/levels';
import gsap from 'gsap/gsap-core';
import SoundHandler from './utils/soundHandler';

export default class WebGL {
  constructor({ canvas, config, level }) {
    setLevel(level);
    setConfig(config);
    // Renderer
    this.renderer = new Renderer({ canvas });
    setRenderer(this.renderer);

    // Timing variables
    this.clock = new Clock();
    this.oldElapsedTime = 0;

    // Scene
    this.scene = new Scene();
    this.scene.fog = new FogExp2(0xdfd2bf, 0.007);
    setCurrentScene(this.scene);

    // Add Gui
    InitialiseGui();

    // Camera
    this.camera = new Camera(
      60,
      window.innerWidth / window.innerHeight,
      0.01,
      1000
    );

    this.clock = new Clock();

    // Events
    this.resize();
    window.addEventListener('resize', this.resize.bind(this));

    //PostProcessing
    this.postProcessing = new PostProcessing();

    // Init shapes & loading of assets.
    this.init();

    // Call render
    this.render();

    // Sound handler
    this.soundHandler = new SoundHandler();
    setSoundHandler(this.soundHandler);
    this.gameHasStarted = false;
  }

  async init() {
    // Loading different sequences can start here.
    if (!this.hasLoaded) {
      await this.load(manifest);
      await this.soundHandler.load();

      this.lights = new Lights();
      this.addObjects();

      this.hasLoaded = true;
      Broadcaster.emit(EVENT.GAME_LOADED);
    }
  }

  // Adding the physics objects, these are all located in 3D/physics.
  addObjects() {
    this.physics = new Physics();
    this.physics.api.createScene();
    this.ladbroke = new Ladbroke();
    this.crowd = new Crowd();
    this.geese = new Geese();
    // first basketball should be waiting at the final position for the camera.
    this.basketball = new Basketball(true);
    this.opponents = new Opponents(this.physics);

    this.ringcasters = new Ringcasters();
    this.debugWindow = new WindowShader();
    this.sky = new Sky();
    this.cloud = new Cloud();
    this.addEvents();
    this.levelUpdate(1);
  }

  addEvents() {
    this.renderer.domElement.addEventListener('pointerdown', (e) => {
      if (this.secondaryBasketball) {
        return this.secondaryBasketball.activate(e);
      }
      this.basketball.activate(e);
    });

    this.renderer.domElement.addEventListener('pointerup', (e) => {
      if (this.secondaryBasketball) {
        return this.secondaryBasketball.release(e);
      }
      this.basketball.release(e);
    });

    Broadcaster.on(EVENT.GAME_START, () => {
      this.gameHasStarted = true;
      this.soundHandler.enabled = true;
      setAllowScoring(true);
    });

    Broadcaster.on(EVENT.SHOOT_BALL, () => {
      this.isShooting = false;
      this.hasScored = false;

      if (this.secondaryBasketball) {
        this.basketball.animateOutAndRemove();
        this.basketball = this.secondaryBasketball;
      }

      // Update global basketball.
      setBasketball(this.basketball);
      this.isShooting = true;
      this.physics.shootBall(this.basketball);
      this.secondaryBasketball = new Basketball();
    });

    Broadcaster.on(EVENT.LEVEL_UPDATE, (newLevel) => {
      this.levelUpdate(newLevel);
    });

    Broadcaster.on(EVENT.GAME_RESET, () => {
      this.levelUpdate(1);
      this.gameHasStarted = false;
      this.soundHandler.enabled = true;
    });

    Broadcaster.on(EVENT.TIME_OUT, () => {
      this.soundHandler.enabled = false;
    });
  }

  levelUpdate(level) {
    if (!LEVEL[level]) return;
    setLevel(level);
    setConfig(LEVEL[level]);
    setTimeOfDay(level);

    const levelAnimation = { colorValue: 0 };
    gsap.to(levelAnimation, {
      colorValue: 1,
      duration: 1,
      onUpdate: () => {
        this.postProcessing.setBlackLevel(levelAnimation.colorValue);
      },
      onComplete: () => {
        // Do level changes here, like setting time of day.
        this.camera.reposition();
        this.opponents.removeOpponents();
        this.opponents.makeOpponents();
        this.setTime();
        this.lights.setTime(timeOfDay);
        this.sky.setTime(timeOfDay);
        this.cloud.setTime(timeOfDay);
        this.ladbroke.setTime(timeOfDay);
        this.ladbroke.setLevel(level);
        this.crowd.setLevel(level);
        this.geese.setLevel(level);
        if (this.gameHasStarted) setAllowScoring(true);

        if (this.secondaryBasketball) this.secondaryBasketball.enabled = true;

        // Reset the animation on the end of the changes.
        gsap.to(levelAnimation, {
          colorValue: 0,
          duration: 1,
          onUpdate: () => {
            this.postProcessing.setBlackLevel(levelAnimation.colorValue);
          },
        });
      },
    });
  }

  load(manifest = { textures: [], glb: [] }) {
    return new Promise((resolve) => {
      Loader.load(manifest).then(() => {
        resolve();
      });
    });
  }

  resize() {
    setSizes({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    if (this.camera) {
      this.camera.resize();
    }

    if (this.renderer) {
      this.renderer.resize();
    }
  }

  update() {
    const elapsedTime = this.clock.getElapsedTime();
    const deltaTime = elapsedTime - this.oldElapsedTime;
    setElapsedTime(elapsedTime);
    setDeltaTime(deltaTime);

    if (this.opponents) {
      this.opponents.updateOpponents();
    }

    if (this.basketball && !this.isShooting) {
      this.basketball.keepInFrontCamera();
    }

    if (this.secondaryBasketball) {
      this.secondaryBasketball.keepInFrontCamera();
    }

    if (this.basketball && this.basketball.ballMesh) {
      this.basketball.updateBallMesh();
    }
    if (this.secondaryBasketball && this.secondaryBasketball.ballMesh) {
      this.secondaryBasketball.updateBallMesh();
    }
    // We update the shape location on the worker as well.
    if (this.physics && this.isShooting) {
      this.physics.updateWorld(deltaTime);
    }

    this.camera.orbitControls.update();

    if (this.ringcasters && this.isShooting && !this.hasScored) {
      this.hasScored = this.ringcasters.test();
    }

    if (this.ladbroke) {
      this.ladbroke.update(deltaTime);
    }

    if (this.crowd) {
      this.crowd.update(deltaTime);
    }

    if (this.geese) {
      this.geese.update(deltaTime);
    }

    //this.renderer.render(this.scene, this.camera);
    this.postProcessing.render();

    this.oldElapsedTime = elapsedTime;
    window.requestAnimationFrame(this.update.bind(this));
  }

  render() {
    this.update();
  }

  setTime() {
    const fogColors = [
      new Color(0xdfd2bf),
      new Color(0xd57e68),
      new Color(0x802e1a),
      new Color(0x806a46),
    ];

    let scaledTimeOfDay = timeOfDay * 3;
    let mixFactor = scaledTimeOfDay % 1;
    let mixFrom = Math.floor(scaledTimeOfDay);
    let mixTo = Math.ceil(scaledTimeOfDay);

    this.scene.fog.color.lerpColors(
      fogColors[mixFrom],
      fogColors[mixTo],
      mixFactor
    );
  }
}
