import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectShowSpotifyPlayer } from '../../../../store/slices/appSlice';

import bkgAsset from '../../../../assets/images/player-bkg.png';
import { BREAKPOINTS } from '../../../../common/constants/responsive';

const PlayerDrawer = styled.div`
  position: absolute;
  top: 51px;
  left: 50%;
  transform: ${(props) =>
    props.showSpotifyPlayer ? 'translate(-50%, 0%)' : 'translate(-50%, -100%)'};
  padding: 0 32px 7px;
  z-index: 1;

  background-image: url(${bkgAsset});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;

  transition: all 0.4s;

  @media (min-width: ${BREAKPOINTS.MEDIUM}) {
    top: 55px;
  }
`;

const Player = styled.iframe`
  width: 250px;
  height: 80px;
`;

export const SpotifyPlayer = () => {
  const showSpotifyPlayer = useSelector(selectShowSpotifyPlayer);

  return (
    <PlayerDrawer as="section" showSpotifyPlayer={showSpotifyPlayer}>
      <Player
        src="https://open.spotify.com/embed/playlist/1keGwKMelcTOQFryZJkzWp"
        title="AJ Tracey"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
      />
    </PlayerDrawer>
  );
};
