import styled from 'styled-components';
import { RESPONSIVE } from '../../../../common/constants/responsive';
import { minMaxSizing } from '../../../../utils/typeStyling';

const minWidth = RESPONSIVE.MIN_DEFAULT;
const maxWidth = RESPONSIVE.MAX_DEFAULT;

const minTransform = -20;
const maxTransform = -40;

export const TheGame = styled.img`
  ${minMaxSizing({ css: 'width', min: 135, max: 320 })};
  ${minMaxSizing({ css: 'margin-top', min: 7, max: 20 })};

  /* Offset title */
  transform: translateY(${minTransform}px);

  @media (min-width: ${minWidth}px) {
    transform: translateY(
      calc(
        -10px + (${maxTransform} - -10) * ((100vw - ${minWidth}px) /
              (${maxWidth} - ${minWidth}))
      )
    );
  }

  @media (min-width: ${maxWidth}px) {
    transform: translateY(${maxTransform}px);
  }
`;
