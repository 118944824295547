import Opponent from './physics/opponent';
import { config } from '../globals/constants';

/**
 * Handles multiple opponents, moving them up on a random timer.
 */
export default class Opponents {
  constructor(physics) {
    this.physics = physics;
    this.makeOpponents();
  }

  async makeOpponents() {
    if (!config || !config.opponents) return;

    this.objects = config.opponents.map((opponent) => {
      return new Opponent(opponent, this.physics, config.id);
    });
  }

  removeOpponents() {
    if (!this.objects || !this.objects.length) return;

    this.objects.map((object) => {
      object.remove();
    });
    this.objects = undefined;
  }

  updateOpponents() {
    if (this.objects) {
      this.objects.map(async (object) => {
        object.updatePlane();
        await this.physics.api.updateOpponents({
          id: object.id,
          position: object.currentOpponent.position,
          rotation: object.currentOpponent.rotation.y,
        });
      });
    }
  }
}
