import { useState, useEffect } from 'react';
import { LEVEL } from '../common/constants/levels';

export const useLevelData = (level) => {
  const [levelData, setLevelData] = useState(null);
  const [levelsCount, setLevelsCount] = useState(null);

  useEffect(() => {
    setLevelData(LEVEL?.[level]);
  }, [level]);

  useEffect(() => {
    setLevelsCount(Object.keys(LEVEL).length);
  }, [level]);

  return [levelData, levelsCount];
};
