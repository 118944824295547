import styled from 'styled-components';
import { BREAKPOINTS } from '../../common/constants/responsive';
import { Button } from '../../components/Button/Button';
import { minMaxSizing } from '../../utils/typeStyling';

export const ContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding-top: 20px;

  overflow-y: auto;

  .main-block {
    display: flex;
    align-items: center;
  }

  .centre-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .spotify-presents {
    margin-bottom: 12px;
  }

  .start-button {
  }
`;

export const StartButton = styled(Button)`
  ${minMaxSizing({ css: 'margin-top', min: 10, max: 44 })};

  opacity: inherit;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${BREAKPOINTS.MEDIUM}) {
    margin-bottom: 80px;
  }
`;
