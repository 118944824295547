import {
  Color,
  DirectionalLight,
  DirectionalLightHelper,
  PointLight,
  SpotLight,
  SpotLightHelper,
  HemisphereLight,
} from 'three';
import {
  Lensflare,
  LensflareElement,
} from 'three/examples/jsm/objects/Lensflare';
import { Cache } from '../utils/cache';
import { CURRENT_SCENE, GUI, DEBUG_LIGHTS } from '../globals/constants';

class Lights {
  constructor() {
    this.directionalColors = [
      new Color(0xfff5e5),
      new Color(0xffbeb2),
      new Color(0xff2a00),
      new Color(0xffd28c),
    ];
    this.hemisphereColors = [
      new Color(0xdfd2bf),
      new Color(0x888cbf),
      new Color(0x734563),
      new Color(0x4d3d3d),
    ];
    //original light values
    //0xdfd2bf
    //0x7377a2
    //0x47283c
    //0x312727

    this.hemisphereGroundColors = [
      new Color(0x6e685e),
      new Color(0x3a3c52),
      new Color(0x24141e),
      new Color(0x1a1414),
    ];

    this.hemisphereLight = new HemisphereLight(0xffffff, 0x000000, 0.9);
    this.directionalLight = new DirectionalLight(0xffffff, 0);

    this.pointLight = new PointLight(0xffffff, 0, 4);
    this.pointLight.position.set(0, 3.45, 2);

    this.spot1 = this.addSpotLight();
    this.spot2 = this.addSpotLight();

    this.spot1.position.set(-6.95, 6.8, -1.45);
    this.spot1.target.position.set(-6, 0, 3);

    this.spot2.position.set(6.95, 6.8, -1.45);
    this.spot2.target.position.set(6, 0, 3);

    const flareTexture = Cache.get('flare');
    this.lensflare1 = new Lensflare();
    this.lensflare2 = new Lensflare();

    this.lensflare1.addElement(new LensflareElement(flareTexture, 1792, 0));
    this.lensflare2.addElement(new LensflareElement(flareTexture, 1792, 0));

    this.lensflare1.visible = false;
    this.lensflare2.visible = false;

    this.spot1.add(this.lensflare1);
    this.spot2.add(this.lensflare2);

    CURRENT_SCENE.add(
      this.hemisphereLight,
      this.directionalLight,
      this.pointLight,
      this.spot1,
      this.spot1.target,
      this.spot2,
      this.spot2.target
    );

    if (DEBUG_LIGHTS) {
      this.addDebuggers();
    }
  }

  setTime(timeOfDay) {
    const fromIndex = Math.floor(timeOfDay * 3);
    const toIndex = Math.ceil(timeOfDay * 3);
    const lerpAmount = (timeOfDay * 3) % 1;

    //update directional light
    const sunElevation = this.mapRange(
      timeOfDay,
      0,
      1,
      Math.PI * 0.8,
      Math.PI * 1.1
    );
    this.directionalLight.position.y = Math.sin(sunElevation);
    this.directionalLight.position.z = Math.cos(sunElevation);
    this.directionalLight.intensity = Math.pow(1 - timeOfDay, 0.5) * 0.7;

    const directionalFrom = this.directionalColors[fromIndex];
    const directionalTo = this.directionalColors[toIndex];
    this.directionalLight.color.lerpColors(
      directionalFrom,
      directionalTo,
      lerpAmount
    );

    //update hemisphere light
    const colorFrom = this.hemisphereColors[fromIndex];
    const colorTo = this.hemisphereColors[toIndex];
    this.hemisphereLight.color.lerpColors(colorFrom, colorTo, lerpAmount);

    const groundFrom = this.hemisphereGroundColors[fromIndex];
    const groundTo = this.hemisphereGroundColors[toIndex];
    this.hemisphereLight.groundColor.lerpColors(
      groundFrom,
      groundTo,
      lerpAmount
    );

    if (timeOfDay >= 10 / 16) {
      this.pointLight.intensity = 1;
      this.spot1.intensity = 1.3;
      this.spot2.intensity = 1.3;
      this.lensflare1.visible = true;
      this.lensflare2.visible = true;
    } else {
      this.pointLight.intensity = 0;
      this.spot1.intensity = 0;
      this.spot2.intensity = 0;
      this.lensflare1.visible = false;
      this.lensflare2.visible = false;
    }
  }

  addSpotLight() {
    const newSpot = new SpotLight(0xffffff, 0);
    newSpot.distance = 30;
    newSpot.angle = 3.1415 / 3.5;
    newSpot.penumbra = 0.3;
    newSpot.decay = 0.2;

    return newSpot;
  }

  addDebuggers() {
    const directionalLightHelper = new DirectionalLightHelper(
      this.directionalLight,
      0.2
    );

    const folder = GUI.addFolder('lights');

    var conf = { color: this.directionalLight.color.getHex() };
    folder.addColor(conf, 'color').onChange(() => {
      const colorObj = new Color(conf.color);

      this.directionalLight.color = new Color(colorObj.getStyle());
    });

    folder.add(this.directionalLight, 'intensity').min(0).max(5).step(0.1);

    CURRENT_SCENE.add(directionalLightHelper);
  }

  mapRange(input, inputStart, inputEnd, outputStart, outputEnd) {
    const slope = (outputEnd - outputStart) / (inputEnd - inputStart);
    const output = outputStart + slope * (input - inputStart);
    return output;
  }
}

export default Lights;
