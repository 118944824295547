import { Color, Mesh, PlaneGeometry, ShaderMaterial } from 'three';
import {
  CURRENT_SCENE,
  DEBUG_WINDOWS,
  timeOfDay,
} from '../../globals/constants';

export default class CloudsShader {
  constructor(texture) {
    const uniforms = {
      uSunColor: { value: new Color(0xffffff) },
      uTexture: { value: texture },
      uTimeOfDay: { value: 0 },
    };

    const vertexShader = `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `;

    const fragmentShader = `
      #ifndef OPTI
        uniform vec3 uSunColor;
        uniform sampler2D uTexture;
        uniform float uTimeOfDay;
      #endif

      varying vec2 vUv;
      void main() {
        vec4 cloudsTexture = texture2D(uTexture, mod(vUv+vec2(0.,3.*uTimeOfDay),1.));
        vec3 lightDirection = normalize(vec3(0.,1.,0.4 * (0.75-uTimeOfDay)));
        vec3 cloudsNormal = (cloudsTexture.xyz -vec3(0.5)) * 4.;
        cloudsNormal *= pow(1.-vUv.y, 2.);
        cloudsNormal = (cloudsNormal+vec3(0.5));
        cloudsNormal.z = 1.;
        cloudsNormal = normalize(cloudsNormal);
        float NDotV = abs(dot(cloudsNormal, lightDirection));
        vec3 cloudDiffuse = NDotV * uSunColor;
        float densityAlpha = pow(cloudsTexture.a,2.5);
        float falloffAlpha = pow(max(1. - length(2.*(vUv-vec2(0.5,0.5))),0.),0.5);
        float cloudsAlpha = densityAlpha * falloffAlpha;
        
        gl_FragColor = vec4(vec3(cloudDiffuse), cloudsAlpha);
        //gl_FragColor = vec4(uSunColor, 1.);
      }`;

    this.material = new ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
    });
  }
}
