import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useCountDown from '../../../../hooks/useCountdown';
import { msToSeconds } from '../../../../utils/msToSeconds';
import { secondsToMs } from '../../../../utils/secondsToMs';
import { splitCountdown } from './splitCountdown';
import Broadcaster from '../../../../utils/Broadcaster';
import {
  selectGameStatus,
  selectLevel,
} from '../../../../store/slices/gameSlice';

import { EVENT } from '../../../../common/constants/events';
import { GAME } from '../../../../common/constants/game';
import { useLevelData } from '../../../../hooks/useLevelData';
import { Numeral } from '../../../Numeral/Numeral';

const Styles = styled.div`
  .timer {
    display: flex;
    margin: 0 -1px;

    .numeral {
      padding: 1px;
    }
  }
`;

export const GameCountdownTimer = () => {
  const level = useSelector(selectLevel);
  const dispatch = useDispatch();

  const gameStatus = useSelector(selectGameStatus);
  const [timeLeft, { start, pause }] = useCountDown(
    secondsToMs(30),
    secondsToMs(1)
  );
  const [levelData] = useLevelData(level);

  useEffect(() => {
    if (gameStatus === GAME.PLAYING) {
      start(secondsToMs(levelData?.count));
    }
  }, [gameStatus, start, levelData]);

  useEffect(() => {
    if (gameStatus === GAME.PAUSED) {
      pause();
    }
  }, [gameStatus, pause]);

  useEffect(() => {
    if (timeLeft === 0) {
      Broadcaster.emit(EVENT.TIME_OUT);
    }
  }, [timeLeft, dispatch, pause]);

  return (
    <Styles>
      {timeLeft === null && (
        <div className="timer">
          <Numeral
            number={splitCountdown(levelData?.count)?.[0]}
            className="numeral"
          />
          <Numeral
            number={splitCountdown(levelData?.count)?.[1]}
            className="numeral"
          />
        </div>
      )}
      {timeLeft !== null && (
        <div className="timer">
          <Numeral
            number={splitCountdown(msToSeconds(timeLeft))?.[0]}
            className="numeral"
          />
          <Numeral
            number={splitCountdown(msToSeconds(timeLeft))?.[1]}
            className="numeral"
          />
        </div>
      )}
    </Styles>
  );
};
