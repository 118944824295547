import { PerspectiveCamera, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  config,
  DEBUG,
  GUI,
  Renderer,
  setCamera,
  SIZES,
} from '../globals/constants';
import Broadcaster from '../../utils/Broadcaster';
import { EVENT } from '../../common/constants/events';
import gsap from 'gsap/gsap-core';

class Camera extends PerspectiveCamera {
  constructor(fov, aspect, near, far) {
    super(fov, aspect, near, far);
    this.tweenPosition = new Vector3();
    this.tweenTarget = new Vector3();
    this.firstTime = true;

    this.position.copy(config.camera.position);
    this.orbitControls = new OrbitControls(this, Renderer.domElement);
    this.orbitControls.enableDamping = true;
    this.orbitControls.rotateSpeed = 0.25;
    this.orbitControls.target.copy(config.camera.target);
    this.orbitControls.enableZoom = false;
    setCamera(this);

    // Add gui when DEBUG is true.
    if (DEBUG && !GUI) {
      this.addDebuggers();
    }
  }

  resize() {
    this.aspect = SIZES.width / SIZES.height;
    this.updateProjectionMatrix();
  }

  reposition() {
    const {
      startPosition,
      startTarget,
      duration,
      position,
      target,
    } = config.camera;
    if (!position || !target) return;

    if (!duration) {
      this.position.copy(position);
      this.orbitControls.target.copy(target);
      Broadcaster.emit(EVENT.START_TIMER);
    } else {
      if (this.firstTime) {
        const cameraTween = { progress: 0 };
        gsap.to(cameraTween, {
          progress: 1,
          duration: duration,
          onUpdate: () => {
            this.tweenPosition.lerpVectors(
              startPosition,
              position,
              cameraTween.progress
            );
            this.tweenTarget.lerpVectors(
              startTarget,
              target,
              cameraTween.progress
            );
            this.position.copy(this.tweenPosition);
            this.orbitControls.target.copy(this.tweenTarget);
          },
          onComplete: () => {
            this.position.copy(position);
            this.orbitControls.target.copy(target);
            this.firstTime = false;
            Broadcaster.emit(EVENT.START_TIMER);
          },
        });
      } else {
        this.position.copy(position);
        this.orbitControls.target.copy(target);
        Broadcaster.emit(EVENT.START_TIMER);
      }
    }
  }

  addDebuggers() {
    const folder = GUI.addFolder('Camera');
    folder
      .add(this, 'fov')
      .min(45)
      .max(150)
      .step(0.5)
      .listen()
      .onChange(() => {
        this.updateProjectionMatrix();
      });
  }
}

export default Camera;
