import styled, { css, keyframes } from 'styled-components';
import { minMaxSizing } from '../../../../utils/typeStyling';

import frame1Asset from '../../../../assets/images/aj-talking/AJ_Head_sunglasses_talk-flip_1.png';
import frame2Asset from '../../../../assets/images/aj-talking/AJ_Head_sunglasses_talk-flip_2.png';
import frame3Asset from '../../../../assets/images/aj-talking/AJ_Head_sunglasses_talk-flip_3.png';
import frame4Asset from '../../../../assets/images/aj-talking/AJ_Head_sunglasses_talk-flip_4.png';

const bkg = keyframes`
  0% {
    background-image: url(${frame1Asset});
  }
  33.333% {
    background-image: url(${frame2Asset});
  }
  66.666% {
    background-image: url(${frame3Asset});
  }
  100% {
    background-image: url(${frame4Asset});
  }
`;

export const AJHead = styled.div`
  display: inline-block;
  position: relative;
  ${minMaxSizing({ css: 'width', min: 107, max: 227 })};
  ${minMaxSizing({ css: 'height', min: 107 * 1.22, max: 227 * 1.22 })};
  background-size: 100%;
  background-repeat: no-repeat;

  image-rendering: pixelated;

  background-image: url(${frame1Asset});

  ${(props) =>
    props.playing &&
    css`
      animation: ${bkg} 0.3s linear infinite;
    `}

  z-index: 2;
`;
