import styled from 'styled-components';
import { RESPONSIVE } from '../../../../common/constants/responsive';

export const TransitionHead = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  transition: linear 0.2s;
  opacity: 0;

  width: calc(
    ${(props) => props.minwidth}px +
      (${(props) => props.maxwidth} - ${(props) => props.minwidth}) *
      (
        (100vw - ${RESPONSIVE.MIN_DEFAULT}px) /
          (${RESPONSIVE.MAX_DEFAULT} - ${RESPONSIVE.MIN_DEFAULT})
      )
  );
`;
