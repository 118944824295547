/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EVENT } from '../../common/constants/events';
import { GAME } from '../../common/constants/game';
import { STAGE } from '../../common/constants/stages';
import { setStage } from '../../store/slices/appSlice';
import {
  increaseLevel,
  increaseScore,
  resetLevel,
  resetScore,
  selectLevel,
  setGameStatus,
} from '../../store/slices/gameSlice';
import Broadcaster from '../../utils/Broadcaster';
import { trackEvent } from '../../utils/trackEvent';
import { ACTION, CATEGORY } from '../../common/constants/tracking';

export const EventsListener = () => {
  const dispatch = useDispatch();
  const level = useSelector(selectLevel);

  useEffect(() => {
    Broadcaster.on(EVENT.GAME_LOADED, () => {});

    return Broadcaster.off(EVENT.GAME_LOADED, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.INCREASE_SCORE, () => {
      trackEvent(CATEGORY.GAME, ACTION.STATE, 'score-basket');
      dispatch(increaseScore());
    });

    return Broadcaster.off(EVENT.INCREASE_SCORE, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.INCREASE_LEVEL, () => {
      trackEvent(CATEGORY.GAME, ACTION.STATE, `level-${level}-complete`);

      dispatch(increaseLevel());
      dispatch(resetScore());
    });

    return Broadcaster.off(EVENT.INCREASE_LEVEL, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.START_TIMER, () => {
      if (level === 1) {
        dispatch(setGameStatus(GAME.READY));
      }
    });

    return Broadcaster.off(EVENT.START_TIMER, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.GAME_START, () => {
      // dispatch(setGameStatus(GAME.PLAYING));
    });

    return Broadcaster.off(EVENT.GAME_START, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.GAME_RESET, () => {
      dispatch(resetScore());
      dispatch(resetLevel());
      dispatch(setStage(STAGE.GAME));
    });

    return Broadcaster.off(EVENT.GAME_RESET, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.GAME_OVER, () => {
      dispatch(setGameStatus(GAME.OVER));
      dispatch(setStage(STAGE.GAME_OVER));
    });

    return Broadcaster.off(EVENT.GAME_OVER, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.GAME_WON, () => {
      dispatch(setGameStatus(GAME.WON));
      dispatch(setStage(STAGE.GAME_OVER));
    });

    return Broadcaster.off(EVENT.GAME_WON, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.TIME_OUT, () => {
      dispatch(setGameStatus(GAME.GAME_OVER));
      dispatch(setStage(STAGE.GAME_OVER));
    });

    return Broadcaster.off(EVENT.TIME_OUT, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.GOAL_REACHED, () => {
      dispatch(setGameStatus(GAME.PAUSED));
    });

    return Broadcaster.off(EVENT.GOAL_REACHED, () => {});
  }, []);

  useEffect(() => {
    Broadcaster.on(EVENT.SHOOT_BALL, () => {
      trackEvent(CATEGORY.GAME, ACTION.CLICK, 'shoot-basket');
    });

    return Broadcaster.off(EVENT.SHOOT_BALL, () => {});
  }, []);

  return null;
};

/* eslint-enable react-hooks/exhaustive-deps */
