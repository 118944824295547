import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderOrangeNumeral } from '../../utils/renderOrangeNumeral';
import { renderWhiteNumeral } from '../../utils/renderWhiteNumeral';

const StyledImage = styled.img`
  height: 30px;
`;

export const Numeral = ({ number, className, orange }) => (
  <StyledImage
    className={className}
    src={orange ? renderOrangeNumeral(number) : renderWhiteNumeral(number)}
    alt={number}
    draggable="false"
  />
);

Numeral.propTypes = {
  number: PropTypes.string,
  className: PropTypes.string,
  orange: PropTypes.bool,
};
