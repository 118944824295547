import { Raycaster, Vector3 } from 'three';
import {
  allowScoring,
  basketball,
  setAllowScoring,
  SoundHandler,
} from '../globals/constants';
import Broadcaster from '../../utils/Broadcaster';
import { EVENT } from '../../common/constants/events';

export default class Ringcasters {
  constructor() {
    this.caster1 = new Raycaster();
    this.caster2 = new Raycaster();
    this.caster3 = new Raycaster();

    this.caster1.far = 1;
    this.caster2.far = 1;
    this.caster3.far = 1;

    this.caster1.set(new Vector3(0, 3.05, 1.8), new Vector3(0, 0, -1));
    this.caster2.set(new Vector3(0, 2.93, 1.8), new Vector3(0, 0, -1));
    this.caster3.set(new Vector3(-0.22, 2.93, 1.58), new Vector3(1, 0, 0));
  }

  test() {
    if (!allowScoring) return;
    const intersects1 = this.caster1.intersectObject(basketball);
    const intersects2 = this.caster2.intersectObject(basketball);
    const intersects3 = this.caster3.intersectObject(basketball);
    if (
      (intersects1.length > 0 && intersects2.length > 0) ||
      (intersects1.length > 0 && intersects3.length > 0)
    ) {
      setAllowScoring(false);
      SoundHandler.playSound('horn', 0.4);
      Broadcaster.emit(EVENT.INCREASE_SCORE);
      return true;
    }

    return false;
  }
}
