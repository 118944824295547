import PropTypes from 'prop-types';
import styled from 'styled-components';

import { minMaxSizing } from '../../utils/typeStyling';

import arrowAsset from '../../assets/images/Arrow_Active.png';

export const StyledButton = styled.div`
  && {
    ${minMaxSizing({ css: 'font-size', min: 21, max: 30 })};

    line-height: 1;
    color: white;
    text-transform: uppercase;

    padding: 0;
    border: 0;

    position: relative;

    &:hover {
      color: #ec5535;

      &:before {
        content: '';
        display: block;
        ${minMaxSizing({ css: 'width', min: 13, max: 19 })};
        ${minMaxSizing({ css: 'height', min: 12, max: 17 })};
        background-image: url(${arrowAsset});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right center;

        position: absolute;
        top: 50%;
        ${minMaxSizing({ css: 'left', min: -21, max: -32 })};
        transform: translateY(-20%);
      }
    }
  }
`;

export const CTA = ({ text, onClick, as = 'button', href }) => (
  <StyledButton
    as={as}
    type={as === 'button' ? 'button' : ''}
    href={href}
    className="bitmap-pro"
    onClick={onClick}
  >
    {text && text}
  </StyledButton>
);

CTA.propTypes = {
  text: PropTypes.string,
  as: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};
