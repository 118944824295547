import { Loader } from 'resource-loader';
import { EventEmitter } from 'events';
import { Cache } from '../utils/cache';

import { RGBFormat, RGBAFormat, Texture } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

class THREELoader extends EventEmitter {
  constructor() {
    super();
    this.loader = new Loader();
    this.gltfLoader = new GLTFLoader();
    this.loader.use(this.use.bind(this));
  }
  load(manifest) {
    return new Promise((resolve) => {
      // Keep track of which items are loaded so far.
      let queueObject = {
        loader:
          manifest.textures && manifest.textures.length > 0 ? false : true,
        glb: manifest.glb && manifest.glb.length > 0 ? false : true,
      };

      if (manifest.textures) {
        // Load actual textures and misc items.
        manifest.textures.forEach((file) => {
          this.loader.add(file);
        });
      }

      // Loading queue for GLB files, with GLTFLoader.
      this.loadGLTFs(manifest.glb).then(() => {
        queueObject.glb = true;
        // If other queue has ended, resolve promise.
        if (queueObject.loader) {
          resolve();
        }
      });

      // On end of loading queue for textures & misc items.
      this.loader.load(() => {
        // If other queue has ended, resolve promise.
        queueObject.loader = true;
        if (queueObject.glb) {
          resolve();
        }
      });
    });
  }

  add(img) {
    this.loader.add(img);
  }

  loadGLTFs(manifest) {
    return new Promise((resolve) => {
      // Keep track of the promises started by the manifest file
      const glbs = [];
      manifest.forEach((file, index) => {
        if (Cache.get(file.name)) {
          console.log(file.name, 'already cached');
        } else {
          // Load file
          this.gltfLoader.load(file.url, (model) => {
            // Make item in Cache and assign the value.
            Cache.set(file.name, {});
            Cache[file.name].data = model;

            // Add to list of glbs.
            glbs.push(file.name);

            // If all glbs are loaded, resolve the list.
            if (glbs.length === manifest.length) {
              resolve();
            }
          });
        }
      });
    });
  }

  use(resource, next) {
    if (Cache.get(resource.name)) {
      console.log(resource.name, 'already cached');
    } else {
      Cache.set(resource.name, {});
      Cache[resource.name].data = resource.data;
    }
    const extension = resource.url.split('.').pop();

    switch (extension) {
      case 'json':
        Cache[resource.name].resource = resource.data;
        next();

        break;
      case 'jpg':
      case 'png':
        /**
         * Textures have to be in the /textures directory!
         */
        const isTexture = resource.url.indexOf('textures') > -1;

        if (isTexture) {
          const texture = new Texture();

          texture.flipY = false;

          texture.image = resource.data;
          texture.format = extension === 'png' ? RGBAFormat : RGBFormat;
          texture.needsUpdate = true;
          Cache[resource.name].resource = texture;
        } else {
          Cache[`${resource.name}`].resource = resource.data;
        }

        next();

        break;
      default:
        next();
    }
  }
  get(id) {
    if (Cache[id]) {
      return Cache[id].resource || Cache[id].data;
    }

    return null;
  }
}
// const miniLoader
const loader = new THREELoader();

export default loader;
