import styled from 'styled-components';
import { RESPONSIVE } from '../../../../common/constants/responsive';
import { minMaxSizing } from '../../../../utils/typeStyling';

const minWidth = RESPONSIVE.MIN_DEFAULT;
const maxWidth = RESPONSIVE.MAX_DEFAULT;

const minTransform = -25;
const maxTransform = -40;

export const FlamingBall = styled.img`
  ${minMaxSizing({ css: 'width', min: 70, max: 223 })};

  /* Offset title */
  transform: translateY(${minTransform}px);

  @media (min-width: ${minWidth}px) {
    transform: translateY(
      calc(
        ${minTransform}px + (${maxTransform} - ${minTransform}) *
          ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth}))
      )
    );
  }

  @media (min-width: ${maxWidth}px) {
    transform: translateY(${maxTransform}px);
  }
`;
