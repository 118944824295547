import PropTypes from 'prop-types';
import styled from 'styled-components';
import { minMaxSizing } from '../../utils/typeStyling';
import { BREAKPOINTS } from '../../common/constants/responsive';
import ajBitmapAsset from '../../assets/images/gifs/AJ_HEAD1_talk_blink-flip.gif';

const transparentBkg = 'rgba(0, 0, 0, 0.8)';
const lightOrange = '#e65131';
const midOrange = '#e33820';
const darkOrange = '#d21407';

const Styles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  margin: 0 auto;
  width: 90%;
  max-width: 540px;

  @media (min-width: ${BREAKPOINTS.XSMALL}) {
    width: 80%;
  }

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    width: 70%;
  }

  .borders {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: ${(props) => (props.cta ? '0' : '1em')};

    .border-block {
      width: 2px;
      @media (min-width: ${BREAKPOINTS.SMALL}) {
        width: 3px;
      }
      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        width: 4px;
      }
    }

    .left-outer,
    .right-outer {
      position: relative;
      height: calc(100% - 12px);

      @media (min-width: ${BREAKPOINTS.SMALL}) {
        height: calc(100% - 18px);
      }
      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        height: calc(100% - 24px);
      }

      background: linear-gradient(180deg, ${lightOrange} 50%, ${midOrange} 50%);
    }
    .left-inner,
    .right-inner {
      position: relative;
      height: calc(100%);
      background-color: ${transparentBkg};

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: 4px;

        @media (min-width: ${BREAKPOINTS.SMALL}) {
          width: 3px;
          height: 6px;
        }
        @media (min-width: ${BREAKPOINTS.MEDIUM}) {
          width: 4px;
          height: 8px;
        }
      }

      &:before {
        top: 0;
        background: linear-gradient(
          180deg,
          ${lightOrange} 50%,
          ${midOrange} 50%
        );
      }

      &:after {
        bottom: 0;
        background: linear-gradient(
          180deg,
          ${darkOrange} 50%,
          ${midOrange} 50%
        );
      }
    }
    .left-inner-l,
    .left-inner-r,
    .right-inner-l,
    .right-inner-r {
      position: relative;
      background-color: ${transparentBkg};

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: 4px;

        @media (min-width: ${BREAKPOINTS.SMALL}) {
          width: 3px;
          height: 6px;
        }
        @media (min-width: ${BREAKPOINTS.MEDIUM}) {
          width: 4px;
          height: 8px;
        }
      }

      &:before {
        top: 0;
        background: linear-gradient(
          180deg,
          ${lightOrange} 50%,
          ${midOrange} 50%
        );
      }

      &:after {
        bottom: 0;
        background: linear-gradient(
          180deg,
          ${darkOrange} 50%,
          ${midOrange} 50%
        );
      }
    }

    .left-inner-l,
    .right-inner-r {
      height: calc(100% - 8px);

      @media (min-width: ${BREAKPOINTS.SMALL}) {
        height: calc(100% - 12px);
      }
      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        height: calc(100% - 16px);
      }
    }
    .left-inner-r,
    .right-inner-l {
      height: calc(100% - 4px);

      @media (min-width: ${BREAKPOINTS.SMALL}) {
        height: calc(100% - 6px);
      }
      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        height: calc(100% - 8px);
      }
    }
  }

  .content {
    position: relative;
    width: calc(100% - 16px);

    @media (min-width: ${BREAKPOINTS.SMALL}) {
      width: calc(100% - 24px);
    }
    @media (min-width: ${BREAKPOINTS.MEDIUM}) {
      width: calc(100% - 32px);
    }

    min-height: 100px;
    display: flex;
    align-items: center;

    background-color: ${transparentBkg};

    border-top: 2px solid ${lightOrange};
    border-bottom: 2px solid ${midOrange};

    @media (min-width: ${BREAKPOINTS.SMALL}) {
      border-top: 3px solid ${lightOrange};
      border-bottom: 3px solid ${midOrange};
    }
    @media (min-width: ${BREAKPOINTS.MEDIUM}) {
      border-top: 4px solid ${lightOrange};
      border-bottom: 4px solid ${midOrange};
    }
  }

  .aj-bitmap {
    position: absolute;
    ${minMaxSizing({ css: 'height', min: 80, max: 160 })};
    top: 50%;
    transform: translateY(-50%);
  }

  .message {
    ${minMaxSizing({ css: 'font-size', min: 18, max: 30 })};
    line-height: 1.2;
    color: white;
    padding: 0.75em 1em;

    @media (min-width: ${BREAKPOINTS.SMALL}) {
      padding: 0.5em 0.75em;
    }
    @media (min-width: ${BREAKPOINTS.MEDIUM}) {
      padding: 0.25em 0.5em;
    }

    transform: translateY(-0.2em);

    ${minMaxSizing({ css: 'margin-left', min: 60, max: 130 })};
  }
`;

export const MessageBox = ({ message, cta, ajBitmap }) => (
  <Styles as="section">
    <div className="borders">
      <div className="border-block left-outer" />
      <div className="border-block left-inner-l" />
      <div className="border-block left-inner-r" />
      <div className="border-block left-inner" />

      <div className="content">
        {ajBitmap && (
          <img
            src={ajBitmapAsset}
            className="aj-bitmap"
            alt="AJ Tracy"
            draggable={false}
          />
        )}

        {message && <p className="bitmap-pro message">{message}</p>}
      </div>

      <div className="border-block right-inner" />
      <div className="border-block right-inner-l" />
      <div className="border-block right-inner-r" />
      <div className="border-block right-outer" />
    </div>

    {cta && cta}
  </Styles>
);

MessageBox.propTypes = {
  message: PropTypes.object.isRequired,
  cta: PropTypes.any.isRequired,
  ajBitmap: PropTypes.bool,
};
