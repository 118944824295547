export const splitCountdown = (count) => {
  if (count <= 9) {
    return ['0', count.toString()];
  }

  if (count > 9) {
    return count.toString().split('');
  }

  return ['3', '0'];
};
