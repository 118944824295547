export const BREAKPOINTS = {
  XSMALL: '400px',
  SMALL: '600px',
  MEDIUM: '768px',
  LARGE: '1024px',
};

export const RESPONSIVE = {
  MIN_DEFAULT: 320,
  MAX_DEFAULT: 1440,
};
