import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useCountDown from '../../../../hooks/useCountdown';
import { msToSeconds } from '../../../../utils/msToSeconds';
import { secondsToMs } from '../../../../utils/secondsToMs';
import { renderBBNumeral } from '../../../../utils/renderBBNumeral';
import {
  selectGameStatus,
  selectLevel,
  setGameStatus,
} from '../../../../store/slices/gameSlice';
import { GAME } from '../../../../common/constants/game';
import { EVENT } from '../../../../common/constants/events';
import Broadcaster from '../../../../utils/Broadcaster';

const Numeral = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 120px;
`;

export const StartTimer = () => {
  const dispatch = useDispatch();
  const gameStatus = useSelector(selectGameStatus);
  const level = useSelector(selectLevel);
  const [timeLeft, { start }, timerStatus] = useCountDown(
    secondsToMs(3),
    secondsToMs(1)
  );

  useEffect(() => {
    if (gameStatus === GAME.READY && level === 1) {
      start();
    }
  }, [start, gameStatus, level]);

  useEffect(() => {
    if (timerStatus === 'complete') {
      dispatch(setGameStatus(GAME.PLAYING));
      Broadcaster.emit(EVENT.GAME_START);
    }
  }, [timerStatus, level, dispatch]);

  return (
    <>
      {msToSeconds(timeLeft) && (
        <Numeral
          src={renderBBNumeral(msToSeconds(timeLeft))}
          alt={msToSeconds(timeLeft)}
          draggable="false"
        />
      )}
    </>
  );
};
