import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ROUTE } from '../../common/constants/routes';
import { BREAKPOINTS } from '../../common/constants/responsive';
import { getCookie, setCookie } from '../../utils/cookies';
import { minMaxSizing } from '../../utils/typeStyling';

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: white;

  ${minMaxSizing({ css: 'font-size', min: 14, max: 16 })};
  line-height: 1.25;

  ${minMaxSizing({ css: 'padding-top', min: 0.8, max: 1.25, unit: 'rem' })};
  ${minMaxSizing({ css: 'padding-left', min: 0.8, max: 2, unit: 'rem' })};
  ${minMaxSizing({ css: 'padding-right', min: 0.8, max: 2, unit: 'rem' })};
  ${minMaxSizing({ css: 'padding-bottom', min: 1, max: 1.875, unit: 'rem' })};

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      width: 80%;
      margin-bottom: 0;
    }
  }
`;

const Button = styled.button`
  ${minMaxSizing({ css: 'font-size', min: 1.25, max: 1.875, unit: 'rem' })};

  text-decoration: none;
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 1.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const COOKIE_ID = 'cookies-accepted';

export const CookieBanner = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (getCookie(COOKIE_ID)) setAccepted(true);
  }, []);

  const handleClick = useCallback(() => {
    setCookie(COOKIE_ID, 'true', 365);
    setAccepted(true);
  }, []);

  if (accepted) return null;

  return (
    <Banner className="bitmap-pro">
      <p>
        We - and our partners - use cookies to deliver our services and to show
        you ads based on what you're into. By using our website, you agree to
        the use of cookies as described in our{' '}
        <a href={ROUTE.COOKIES} target="_blank" rel="noreferrer noopener">
          Cookie Policy
        </a>
        . That ok?
      </p>
      <Button type="button" onClick={handleClick}>
        All good
      </Button>
    </Banner>
  );
};
