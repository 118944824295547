import { NearestFilter, LinearEncoding, Color } from 'three';

import {
  Camera,
  CURRENT_SCENE,
  elapsedTime,
  deltaTime,
  setDeltaTime,
  SoundHandler,
  level,
} from '../../globals/constants';
import { Cache } from '../../utils/cache';

import Broadcaster from '../../../utils/Broadcaster';
import { Vec3 } from 'cannon';
import { Vector3 } from 'three';
import { EVENT } from '../../../common/constants/events';
import OPPONENT_TYPES from '../../../common/constants/opponentTypes';

export default class Opponent {
  constructor(config, physics, level) {
    this.config = config;
    this.level = level;
    this.type = config.type;
    this.level = level;
    this.physics = physics;

    this.velocity = 0;
    this.basketPosition = new Vector3(0, 3.05, 1.6);
    this.position = this.setInitialPosition(config.offset);
    const opponentsScene = Cache.get('opponents').scene.clone();
    this.opponents = opponentsScene.children;
    this.id = config.id;

    this.basketVector = new Vector3();
    this.forwardVector = new Vector3();
    this.upVector = new Vector3(0, 1, 0);
    this.lateralVector = new Vector3();
    this.localVelocitySum = new Vector3();

    this.makeOpponent(`${this.type}`);
    this.updatePhysicsBody(Cache.get(`${this.type}_01_collider`).scene, false);

    this.muted = true;

    if (this.level === 1) {
      Broadcaster.on(EVENT.GAME_START, () => {
        this.jumpIndex = 0;
        this.muted = false;
        SoundHandler.playOpponent(this.type);
      });
    } else {
      this.muted = false;
      SoundHandler.playOpponent(this.type);
    }

    switch (this.type) {
      case 'basicgirl':
        this.microJumpVelocity = 1;
        this.gravity = -9.82;
        break;
      case 'bossman':
        this.microJumpVelocity = 0;
        this.gravity = -3;
        break;
      case 'businessman':
        this.microJumpVelocity = 1;
        this.gravity = -9.82;
        break;
      case 'dog':
        this.microJumpVelocity = 1;
        this.gravity = -9.82;
        break;
      case 'hipster':
        this.microJumpVelocity = 0;
        this.gravity = -2;
        break;
      case 'oldlady':
        this.microJumpVelocity = 0;
        this.gravity = -2;
        break;
      case 'pig':
        this.microJumpVelocity = 0;
        this.gravity = -9.82;
        break;
      case 'pizza':
        this.microJumpVelocity = 0.8;
        this.gravity = -4;
        break;
      case 'poshboy':
        this.microJumpVelocity = 1;
        this.gravity = -9.82;
        break;
      case 'scootergirl':
        this.microJumpVelocity = 0;
        this.gravity = -5;
        break;
      case 'sign':
        this.microJumpVelocity = 1;
        this.gravity = -9.82;
        break;
      default:
        break;
    }

    // max 1, we'll use this in a mod() later on.
    this.movementProgress = 0;

    // Index for the jump to wait for. Once this jump is done, we'll increase this.
    // This way we only need to wait for the next jump rather than having to loop over all jumps.
    this.currentJump = 0;

    // Keeps up with the number of jumps that have passed. This way we can play sounds based on the jumps that have occurred.
    this.jumpIndex = 0;
  }

  setInitialPosition(configPosition) {
    /*
    Goal: set opponent at a position on the court offset from the camera position in the
    direction of the basket so they are positioned along the line of fire and capable of blocking shots
    */

    //get vector from camera position to basket
    const basketVector = new Vector3().subVectors(
      this.basketPosition,
      Camera.position
    );

    //normalize
    basketVector.normalize();

    //multiply by offset
    basketVector.multiplyScalar(configPosition);

    //add offset to camera position
    const initialPosition = new Vector3().addVectors(
      Camera.position,
      basketVector
    );

    //return value
    initialPosition.y = 0;
    return initialPosition;
  }

  makeOpponent(opponentType) {
    this.currentOpponent = this.opponents.filter(
      (el) => el.name === opponentType
    )[0];

    if (this.currentOpponent.type == 'Mesh') {
      this.currentOpponent.material.transparent = true;
      this.currentOpponent.material.alphaTest = 0.5;
      this.currentOpponent.material.map.minFilter = NearestFilter;
      this.currentOpponent.material.map.magFilter = NearestFilter;
      this.currentOpponent.material.map.encoding = LinearEncoding;
      if (level >= 10) {
        this.currentOpponent.material.emissiveMap = this.currentOpponent.material.map;
        this.currentOpponent.material.emissive = new Color(0xffffff);
        this.currentOpponent.material.emissiveIntensity = 0.7;
      } else {
        this.currentOpponent.material.emissiveIntensity = 0;
      }

      this.currentOpponent.material.needsUpdate = true;
    } else if (this.currentOpponent.type == 'Object3D') {
      //Bossman
      for (let i = 0; i < 3; i++) {
        this.currentOpponent.children[i].material.transparent = true;
        this.currentOpponent.children[i].material.alphaTest = 0.5;
        this.currentOpponent.children[i].material.map.minFilter = NearestFilter;
        this.currentOpponent.children[i].material.map.magFilter = NearestFilter;
        this.currentOpponent.children[i].material.map.encoding = LinearEncoding;
        if (level >= 10) {
          this.currentOpponent.children[
            i
          ].material.emissiveMap = this.currentOpponent.children[
            i
          ].material.map;
          this.currentOpponent.children[i].material.emissive = new Color(
            0xffffff
          );
          this.currentOpponent.children[i].material.emissiveIntensity = 0.7;
        }
        this.currentOpponent.children[i].material.needsUpdate = true;
      }
    }

    this.currentOpponent.position.copy(this.position);

    CURRENT_SCENE.add(this.currentOpponent);
    this.updateTextureMap(1);
  }

  startJump(jumpIndex) {
    this.jumpIndex++;
    this.isJumping = true;
    this.initialVelocity = this.config.jumps[jumpIndex].jumpVelocity;
    this.velocity = this.initialVelocity;
    this.currentOpponent.position.y = 0;

    this.updateLocalPhysics();
    this.updateTextureMap(2);
    this.updatePhysicsBody(Cache.get(`${this.type}_02_collider`).scene);
    if (!this.muted) {
      this.playJumpAudio();
    }
  }

  playJumpAudio() {
    switch (this.type) {
      case OPPONENT_TYPES.DOG:
        SoundHandler.playOpponent(this.type);
        break;
      case OPPONENT_TYPES.POSH_BOY:
        if (this.jumpIndex % 3 === 0) {
          SoundHandler.playOpponent(this.type);
        }
      case OPPONENT_TYPES.BASIC_GIRL:
        if (this.jumpIndex % 5 === 0) {
          SoundHandler.playOpponent(this.type);
        }

      default:
        break;
    }
  }

  async updatePhysicsBody(scene, removeOtherBodies = true) {
    await this.physics.api.makeOpponentWithModel({
      id: this.id,
      meshes: scene.children.map((mesh) => {
        return {
          removeOtherBodies,
          position: mesh.position,
          scale: mesh.scale,
          rotation: new Vec3(
            this.currentOpponent.rotation.x,
            this.currentOpponent.rotation.y,
            this.currentOpponent.rotation.z
          ),
        };
      }),
    });
  }

  updateLocalPhysics() {
    if (this.currentOpponent.type == 'Mesh') {
      this.velocity += this.gravity * deltaTime;
      this.currentOpponent.position.y += this.velocity * deltaTime;
    } else {
      //Bossman
      this.velocity += this.gravity * deltaTime;
      this.currentOpponent.position.y += this.velocity * deltaTime;

      //Hack to make bossman base stay on the ground - apply inverse of movement to it to cancel it out
      this.currentOpponent.children[0].position.y -= this.velocity * deltaTime;
    }
  }

  updateTextureMap(frame) {
    if (this.currentOpponent.material) {
      this.currentOpponent.material.map.image = Cache.get(
        `opponent_atlas_0${frame}`
      ).image;
      this.currentOpponent.material.map.needsUpdate = true;
    } else {
      //Bossman
      for (let i = 0; i < 3; i++) {
        this.currentOpponent.children[i].material.map.image = Cache.get(
          `opponent_atlas_0${frame}`
        ).image;
        this.currentOpponent.children[i].material.map.needsUpdate = true;
      }
    }
  }

  updatePlane() {
    this.handleMovement();
    this.handleRotation();
    this.handleJumping();
  }

  handleJumping() {
    if (this.currentOpponent.position.y > 0) {
      this.updateLocalPhysics();
      if (this.type == 'scootergirl') {
        //hack rotation into animation by making it correspond to jump height
        let maxJumpHeight =
          (this.initialVelocity * this.initialVelocity) / (2 * this.gravity);
        let halfRotation =
          (this.currentOpponent.position.y / maxJumpHeight) * Math.PI;
        let direction = this.velocity / Math.abs(this.velocity);
        this.currentOpponent.rotation.z = halfRotation * direction;
      }
    } else {
      if (this.isJumping) {
        this.isJumping = false;
        this.velocity = 0;

        if (this.currentOpponent.type == 'Mesh') {
          this.currentOpponent.position.y = 0;
        } else {
          //Bossman
          this.currentOpponent.position.y = 0;
          this.currentOpponent.children[0].position.y = 0;
        }
        this.updateTextureMap(1);
        this.updatePhysicsBody(Cache.get(`${this.type}_01_collider`).scene);
      }
    }
  }

  handleRotation() {
    const angle = Camera.orbitControls.getAzimuthalAngle();
    this.currentOpponent.rotation.y = angle;
  }

  getCurrentDirection() {
    const currentMovement = this.config.movement.steps.filter((step) => {
      if (
        this.movementProgress > step.from &&
        this.movementProgress < step.to
      ) {
        return true;
      }
    })[0];

    return currentMovement && currentMovement.direction
      ? currentMovement.direction
      : false;
  }

  handleMovement() {
    this.movementProgress += deltaTime * (1 / this.config.duration);
    // Capping the value between 0 and 1, once 1 is reached, it resets to 0.
    this.movementProgress = this.movementProgress % 1;

    //micro-jumps
    if (this.isJumping == false) {
      if (elapsedTime % 0.3 < 0.05) {
        this.isJumping = true;
        this.velocity = this.microJumpVelocity;
        this.currentOpponent.position.y = 0;
        this.updateLocalPhysics();
      }
    }

    // Reset the jump index when we've reset.
    if (this.movementProgress <= 0.01 && !this.hasResetToZero) {
      this.currentJump = 0;
    }

    if (this.config.jumps[this.currentJump]) {
      if (this.config.jumps[this.currentJump].time < this.movementProgress) {
        this.startJump(this.currentJump);
        this.currentJump++;
      }
    }

    if (this.movementProgress > 0) {
      const currentDirection = this.getCurrentDirection();
      //Goal: remap world space axis aligned orthoganal movement to vector from Camera to basket

      //get camera to basket vector
      this.basketVector.subVectors(this.basketPosition, Camera.position);

      //invert and clamp y to 0 to get forward opponent direction and normalize
      this.forwardVector
        .set(-this.basketVector.x, 0, -this.basketVector.z)
        .normalize();

      //get perpendicular lateral movement vector
      this.lateralVector
        .crossVectors(this.upVector, this.forwardVector)
        .normalize();

      const localLateralVelocity = this.lateralVector
        .clone()
        .multiplyScalar(currentDirection.x);
      const localForwardVelocity = this.forwardVector
        .clone()
        .multiplyScalar(currentDirection.y);
      this.localVelocitySum.addVectors(
        localLateralVelocity,
        localForwardVelocity
      );
      if (currentDirection) {
        //integrate time step
        this.localVelocitySum.multiplyScalar(this.config.speed * deltaTime);
        this.currentOpponent.position.set(
          this.currentOpponent.position.x + this.localVelocitySum.x,
          this.currentOpponent.position.y,
          this.currentOpponent.position.z + this.localVelocitySum.z
        );
      }
    }
  }

  remove() {
    CURRENT_SCENE.remove(this.currentOpponent);
    this.physics.api.removeBodyById({ id: this.id });
    if (this.level === 1) {
      Broadcaster.off(EVENT.GAME_START, () => {});
    }
  }
}
