const OPPONENT_TYPES = {
  BASIC_GIRL: 'basicgirl',
  BOSS_MAN: 'bossman',
  BUSINESS_MAN: 'businessman',
  DOG: 'dog',
  HIPSTER: 'hipster',
  OLD_LADY: 'oldlady',
  PIG: 'pig',
  PIZZA: 'pizza',
  POSH_BOY: 'poshboy',
  SCOOTER_GIRL: 'scootergirl',
  SIGN: 'sign',
};

export default OPPONENT_TYPES;
