import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import sono from 'sono';
import { MessageBox } from '../../components/MessageBox/MessageBox';
import { CTA } from '../../components/CTA/CTA';
import { UIBackground } from '../../components/UIBackground/UIBackground';

import { setStage } from '../../store/slices/appSlice';
import { STAGE } from '../../common/constants/stages';
import { ACTION, CATEGORY } from '../../common/constants/tracking';
import { trackEvent } from '../../utils/trackEvent';

import instructionsStep1Asset from '../../assets/sfx/vo/Intro_line1.mp3';
import instructionsStep2Asset from '../../assets/sfx/vo/Intro_line2.mp3';

const Content = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 38px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;
`;

const instructionsStep1Audio = sono.create(instructionsStep1Asset);
const instructionsStep2Audio = sono.create(instructionsStep2Asset);

export const InstructionsUI = () => {
  const [instruction, setInstruction] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    instructionsStep1Audio?.play();
  }, []);

  return (
    <UIBackground transparent>
      <Content>
        {instruction === 1 && (
          <MessageBox
            message={
              <>
                Alright alright wah gwan cuz, welcome to the RA court, things
                can get a little bit hectic round here...
              </>
            }
            cta={
              <CTA
                text="Next"
                onClick={() => {
                  trackEvent(CATEGORY.GAME, ACTION.STATE, 'instructions-2');
                  instructionsStep1Audio?.stop();
                  instructionsStep2Audio?.play();
                  setInstruction(2);
                }}
              />
            }
            ajBitmap
          />
        )}
        {instruction === 2 && (
          <MessageBox
            message={
              <>
                So there's going to be plenty of people trying to get in your
                way. You gotta avoid them and shoot your baskets.
              </>
            }
            cta={
              <CTA
                text="Next"
                onClick={() => {
                  trackEvent(CATEGORY.GAME, ACTION.STATE, 'instructions-3');
                  instructionsStep2Audio?.stop();
                  dispatch(setStage(STAGE.SPOTIFY_INSTRUCTIONS));
                }}
              />
            }
            ajBitmap
          />
        )}
      </Content>
    </UIBackground>
  );
};
