import { createSlice } from '@reduxjs/toolkit';
import sono from 'sono';

import { DEFAULTSTAGE } from '../../common/constants/stages';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    stage: DEFAULTSTAGE,
    assetsLoaded: false,
    showInstructions: true,
    showSpotifyPlayer: false,
    sfx: true,
  },
  reducers: {
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setAssetsLoaded: (state, action) => {
      state.stage = action.payload;
    },
    restoreInstructions: (state, action) => {
      state.showInstructions = action.payload;
    },
    instructionsRead: (state) => {
      state.showInstructions = false;
    },
    toggleSpotifyPlayer: (state) => {
      state.showSpotifyPlayer = !state.showSpotifyPlayer;
    },
    toggleSfx: (state) => {
      // Toggle sono directly
      if (state.sfx) {
        sono.mute();
      } else {
        sono.unMute();
      }

      state.sfx = !state.sfx;
    },
  },
});

// Export actions
export const {
  setStage,
  setAssetsLoaded,
  instructionsRead,
  toggleSpotifyPlayer,
  toggleSfx,
} = appSlice.actions;

// State selectors
export const selectStage = (state) => state.app.stage;
export const selectAssetsLoaded = (state) => state.app.assetsLoaded;
export const selectShowInstructions = (state) => state.app.showInstructions;
export const selectShowSpotifyPlayer = (state) => state.app.showSpotifyPlayer;
export const selectSfxStatus = (state) => state.app.sfx;

export default appSlice.reducer;
