import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import sono from 'sono';
import { UIBackground } from '../../components/UIBackground/UIBackground';
import { MessageBox } from '../../components/MessageBox/MessageBox';

import { setStage, toggleSpotifyPlayer } from '../../store/slices/appSlice';
import { STAGE } from '../../common/constants/stages';
import { Button } from '../../components/Button/Button';
import { trackEvent } from '../../utils/trackEvent';
import { ACTION, CATEGORY } from '../../common/constants/tracking';

import instructionsStep3Asset from '../../assets/sfx/vo/Intro_line3.mp3';
import pointUpAsset from '../../assets/images/index-pointing-up.png';
import { selectGameStatus } from '../../store/slices/gameSlice';
import { GAME } from '../../common/constants/game';

const Content = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 38px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;

  .album-on {
    color: #b62000;

    &:after {
      content: '';
      display: inline-block;
      width: 1em;
      height: 0.7em;
      background-image: url(${pointUpAsset});
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 0.25em;
    }
  }
`;

const instructionsStep3Audio = sono.create(instructionsStep3Asset);

export const SpotifyInstructionsUI = () => {
  const dispatch = useDispatch();
  const gameStatus = useSelector(selectGameStatus);

  useEffect(() => {
    dispatch(toggleSpotifyPlayer());
  }, [dispatch]);

  useEffect(() => {
    instructionsStep3Audio?.play();
  }, []);

  return (
    <UIBackground transparent>
      <Content>
        <MessageBox
          message={
            <span>
              The more you make, the harder it gets. But you got this. Lets get
              it! <span className="album-on">Album on</span>
            </span>
          }
          cta={
            <Button
              text="Let's go"
              onClick={() => {
                if (gameStatus === GAME.READY) {
                  trackEvent(CATEGORY.GAME, ACTION.STATE, 'start');
                  dispatch(toggleSpotifyPlayer());
                  instructionsStep3Audio.stop();
                  dispatch(setStage(STAGE.GAME));
                }
              }}
              disabled={gameStatus !== GAME.READY}
            />
          }
          ajBitmap
        />
      </Content>
    </UIBackground>
  );
};
