import { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import { copyTextToClipboard } from '../../utils/copy-text';
import { trackEvent } from '../../utils/trackEvent';
import { ACTION, CATEGORY } from '../../common/constants/tracking';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Copied = styled.span`
  font-size: 1rem;
  color: white;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  text-align: center;
  display: block;
  width: 100%;
`;

export const CopyUrl = () => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timer = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  const handleClick = useCallback(() => {
    trackEvent(CATEGORY.OUTRO, ACTION.CLICK, 'share-copy-url');
    copyTextToClipboard(window.location.href);
    setCopied(true);
  }, []);

  return (
    <Wrapper>
      <Button onClick={handleClick} text="Share game" />
      {copied && <Copied className="bitmap-pro">URL Copied</Copied>}
    </Wrapper>
  );
};
