import {
  WebGLCubeRenderTarget,
  CubeCamera,
  PlaneGeometry,
  DoubleSide,
  RGBFormat,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshStandardMaterial,
  NearestFilter,
  LinearFilter,
  LinearMipmapLinearFilter,
  LinearEncoding,
  sRGBEncoding,
  Color,
  Mesh,
  AnimationMixer,
  AnimationClip,
  LoopOnce,
  PointLight,
} from 'three';
import { CURRENT_SCENE, Renderer } from '../globals/constants';
import { Cache } from '../utils/cache';
import { object } from 'prop-types';
export default class Ladbroke {
  constructor() {
    const glb = Cache.get('ladbroke'); // This gets the main glb file from the cache.
    const windowTexture = Cache.get('windowTexture');
    windowTexture.minFilter = NearestFilter;
    windowTexture.magFilter = NearestFilter;

    const emissiveTexture = Cache.get('emissiveTexture');
    emissiveTexture.minFilter = NearestFilter;
    this.globalEmissive = 0;
    emissiveTexture.magFilter = NearestFilter;
    const emissiveLight = new Color(0xffffff);

    this.cubeRenderTarget = new WebGLCubeRenderTarget(128, {
      format: RGBFormat,
      generateMipmaps: true,
      minFilter: LinearMipmapLinearFilter,
    });
    this.cubeCamera = new CubeCamera(1, 10000, this.cubeRenderTarget);
    this.cubeCamera.position.set(0, 10, 0);
    CURRENT_SCENE.add(this.cubeCamera);

    const standardMaterial = glb.scene.traverse((objectType) => {
      if (objectType.isMesh) {
        const existingColor = objectType.material.color;
        const existingMap = objectType.material.map;
        objectType.material = new MeshStandardMaterial();
        objectType.material.envMap = this.cubeRenderTarget.texture;

        objectType.material.color = existingColor;
        if (existingMap != null) {
          objectType.material.map = existingMap;
          if (
            objectType.name.includes('Fence') ||
            objectType.name.includes('Library') ||
            objectType.name.includes('Trellick') ||
            objectType.name.includes('Hedge') ||
            objectType.name.includes('Community')
          ) {
            objectType.material.transparent = true;
            objectType.material.alphaTest = 0.5;
          } else {
            objectType.material.transparent = false;
          }
          objectType.material.map.minFilter = NearestFilter;
          objectType.material.map.magFilter = NearestFilter;
          objectType.material.map.encoding = LinearEncoding;
          objectType.material.metalness = 0;
          objectType.material.roughnessMap = windowTexture;
          objectType.material.roughness = 1;
          objectType.material.envMapIntensity = 1;
          objectType.material.emissiveMap = emissiveTexture;
          objectType.material.emissive = emissiveLight;
          objectType.material.emissiveIntensity = this.globalEmissive;
        }
        if (
          objectType.name.includes('Trellick') ||
          objectType.name.includes('Grenfell') ||
          objectType.name.includes('Silchester')
        ) {
          objectType.material.fog = false;
        }

        if (objectType.name.includes('Police_Van')) {
          let blueLight = new PointLight(0x0000ff, 2, 20);
          blueLight.position.set(0, 2.5, 0);
          blueLight.visible = false;
          objectType.add(blueLight);
        }

        objectType.material.needsUpdate = true;
      }
    });

    this.mesh = glb.scene;
    CURRENT_SCENE.add(this.mesh);

    //initialise animations
    this.animationMixer = new AnimationMixer(this.mesh);
    this.clips = glb.animations;
  }

  update(deltaSeconds) {
    this.animationMixer.update(deltaSeconds);
  }

  initScene() {
    this.setSceneEmissive(0);
    this.hideBus();
    this.switchOffPoliceLights();
  }

  busArrive() {
    const busMesh = this.mesh.getObjectByName('Bus');
    busMesh.visible = true;

    const clip = AnimationClip.findByName(this.clips, 'Bus Arrive');
    const action = this.animationMixer.clipAction(clip);
    this.animationMixer.stopAllAction();
    action.stop();
    action.setLoop(LoopOnce);
    action.clampWhenFinished = true;
    action.play();
  }

  busLeave() {
    const busMesh = this.mesh.getObjectByName('Bus');
    busMesh.visible = true;

    const clip = AnimationClip.findByName(this.clips, 'Bus Leave');
    const action = this.animationMixer.clipAction(clip);
    this.animationMixer.stopAllAction();
    action.stop();
    action.setLoop(LoopOnce);
    action.clampWhenFinished = true;
    action.play();
  }

  policeVan() {
    let policeMesh = this.mesh.getObjectByName('Police_Van');
    policeMesh.children[0].visible = true;

    const clip = AnimationClip.findByName(this.clips, 'Police');
    const action = this.animationMixer.clipAction(clip);
    this.animationMixer.stopAllAction();
    action.stop();
    action.setLoop(LoopOnce);
    action.clampWhenFinished = true;
    action.play();
  }

  hideBus() {
    const busMesh = this.mesh.getObjectByName('Bus');
    busMesh.visible = false;
  }

  switchOffPoliceLights() {
    const policeMesh = this.mesh.getObjectByName('Police_Van');
    policeMesh.children[0].visible = false;
  }

  setLevel(level) {
    level = parseInt(level, 10);
    switch (level) {
      case 1:
        this.initScene();
        break;
      case 2:
        this.busArrive();
        break;
      case 3:
        this.busLeave();
        break;
      case 8:
        this.setSceneEmissive(0.3);
        break;
      case 9:
        this.setSceneEmissive(0.7);
        break;
      case 12:
        this.busArrive();
        break;
      case 13:
        this.busLeave();
        break;
      case 14:
        this.policeVan();
        break;
      case 15:
        this.switchOffPoliceLights();
        break;
      default:
        break;
    }
  }

  setTime(timeOfDay) {
    // Update the render target cube
    this.mesh.visible = false;
    this.cubeCamera.update(Renderer, CURRENT_SCENE);
    this.mesh.visible = true;
  }

  setSceneEmissive(emissiveLevel) {
    this.mesh.traverse((objectType) => {
      if (objectType.isMesh) {
        const existingMap = objectType.material.map;
        if (existingMap != null) {
          objectType.material.emissiveIntensity = emissiveLevel;
          objectType.material.needsUpdate = true;
        }
      }
    });
  }
}
