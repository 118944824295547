import { useState, useEffect, useCallback } from 'react';
import { PlayState } from 'react-gsap';
import sono from 'sono';
import { EVENT } from '../common/constants/events';
import { ACTION, CATEGORY } from '../common/constants/tracking';
import { trackEvent } from '../utils/trackEvent';
import Broadcaster from '../utils/Broadcaster';

export const useTransitionAssets = (levelData, level) => {
  const [titlePlayState, setTitlePlayState] = useState(PlayState.stop);
  const [headPlayState, setHeadPlayState] = useState(PlayState.stop);
  const [transitionAudio, setTransitionAudio] = useState(null);

  useEffect(() => {
    if (levelData) {
      const audio = sono
        .get(`${levelData?.transitions?.audio}`)
        .once('ended', () => {
          setTitlePlayState(PlayState.restartReverse);
          setHeadPlayState(PlayState.restartReverse);
          setTimeout(() => {
            if (level < 16) {
              Broadcaster.emit(EVENT.INCREASE_LEVEL, () => {});
            }
            if (level === 16) {
              trackEvent(CATEGORY.GAME, ACTION.STATE, 'level-16-complete');
              Broadcaster.emit(EVENT.GAME_WON);
            }
          }, 350);
        });

      setTransitionAudio(audio);
    }
  }, [level, levelData]);

  const startAnimations = useCallback(() => {
    setTitlePlayState(PlayState.play);
    setTimeout(() => {
      setHeadPlayState(PlayState.play);
    }, 400);
  }, []);

  return [transitionAudio, titlePlayState, headPlayState, startAnimations];
};
