import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { SFXButton } from './components/SFXButton/SFXButton';
import { LegalFooter } from './components/LegalFooter/LegalFooter';

import { STAGE } from '../../common/constants/stages';
import { selectStage } from '../../store/slices/appSlice';
import { minMaxSizing } from '../../utils/typeStyling';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  ${minMaxSizing({ css: 'padding-left', min: 16, max: 64 })};
  ${minMaxSizing({ css: 'padding-right', min: 16, max: 64 })};

  ${minMaxSizing({ css: 'font-size', min: 14, max: 20 })};
  line-height: 1;
  color: white;

  ${minMaxSizing({ css: 'margin-bottom', min: 8, max: 38 })};
`;

export const Footer = () => {
  const stage = useSelector(selectStage);

  return (
    <Wrapper as="footer" className="bitmap-pro">
      <SFXButton />

      {(stage === STAGE.INTRO || stage === STAGE.GAME_OVER) && <LegalFooter />}
    </Wrapper>
  );
};
