import { LEVEL } from '../../common/constants/levels';

export const IS_DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const DEBUG = IS_DEVELOPMENT ? true : false;
export const DEBUG_LIGHTS = false;
export const DEBUG_WINDOWS = IS_DEVELOPMENT ? true : false;

export let SIZES = { width: 0, height: 0 };
export function setSizes(val) {
  SIZES = val;
}

export let CURRENT_SCENE = null;
export function setCurrentScene(val) {
  CURRENT_SCENE = val;
}

export let GUI = null;
export function setGUI(val) {
  GUI = val;
}

export let Camera = null;
export function setCamera(val) {
  Camera = val;
}

export let Renderer = null;
export function setRenderer(val) {
  Renderer = val;
}

export let SoundHandler = null;
export function setSoundHandler(val) {
  SoundHandler = val;
}

export let allowScoring = false;
export function setAllowScoring(val) {
  allowScoring = val;
}

export let elapsedTime = 0;
export function setElapsedTime(val) {
  elapsedTime = val;
}

export let basketball = null;
export function setBasketball(val) {
  basketball = val;
}

export let deltaTime = 0;
export const setDeltaTime = (val) => {
  deltaTime = Math.min(1 / 20, val);
};

export let timeOfDay = 0;
export const setTimeOfDay = (level) => {
  timeOfDay = level / 16;
};

export let level = 1;
export const setLevel = (newLevel) => {
  level = newLevel;
};

export let config = LEVEL[level];
export const setConfig = (newConfig) => {
  config = newConfig;
};
