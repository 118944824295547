import styled from 'styled-components';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UIBackground } from '../../components/UIBackground/UIBackground';
import { AreYouReady } from './components/AreYouReady/AreYouReady';
import { LoadingBar } from './components/LoadingBar/LoadingBar';

import areYouReadyAsset from '../../assets/images/gifs/loading_copy_twirl.gif';
import { trackEvent } from '../../utils/trackEvent';
import { selectAssetsLoaded, setStage } from '../../store/slices/appSlice';
import { STAGE } from '../../common/constants/stages';
import { ACTION, CATEGORY } from '../../common/constants/tracking';

const Content = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 38px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;
`;

export const LoadingUI = () => {
  const dispatch = useDispatch();
  const assetsLoaded = useSelector(selectAssetsLoaded);
  useEffect(() => {
    trackEvent(CATEGORY.INTRO, ACTION.LOAD, 'game');
  }, []);

  useEffect(() => {
    if (assetsLoaded) {
      trackEvent(CATEGORY.GAME, ACTION.STATE, 'instructions-1');
      dispatch(setStage(STAGE.GAME));
    }
  }, [assetsLoaded, dispatch]);

  return (
    <UIBackground>
      <Content>
        <AreYouReady src={areYouReadyAsset} draggable="false" />
        <LoadingBar />
      </Content>
    </UIBackground>
  );
};
