import styled from 'styled-components';
import { Tween, Timeline } from 'react-gsap';

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ballAsset from '../../../../assets/images/gifs/loading_small_ball.gif';
import { minMaxSizing } from '../../../../utils/typeStyling';
import { setStage } from '../../../../store/slices/appSlice';
import { STAGE } from '../../../../common/constants/stages';

const Styles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 100%;
  ${minMaxSizing({ css: 'max-width', min: 240, max: 540 })};

  margin: 0 16px;
  ${minMaxSizing({ css: 'margin-top', min: 50, max: 100 })};

  .loading-section {
    position: relative;
    width: calc(100% - 32px);
    height: 24px;
    background-color: white;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 4px;
      background-color: #f6c39c;
    }
  }

  .progress {
    position: absolute;
    top: 0;

    background-color: #ec5535;
    width: ${(props) => `calc(${props.progress}% - 4px)`};
    height: 24px;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: #d21307;
    }

    &:after {
      content: '';
      position: absolute;
      right: -12px;
      width: 27px;
      height: 24px;
      background-image: url(${ballAsset});
      background-size: contain;
      transform: trans;
      z-index: 2;
    }
  }

  .left-outer {
    width: 4px;
    background-color: #d21307;
    height: calc(24px - 8px);
  }

  .left-inner {
    position: relative;
    width: 4px;
    height: 24px;
    background-color: #ec5535;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      height: 4px;
      background-color: #d21307;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .right-inner {
    position: relative;
    width: 4px;
    height: 24px;
    background-color: white;

    &:before {
      content: '';
      position: absolute;
      top: 0;

      display: block;
      width: 4px;
      height: 4px;
      background-color: #f6c39c;
    }

    &:before {
    }
  }
  .right-outer {
    position: relative;
    width: 4px;
    background-color: white;
    height: calc(24px - 8px);

    &:before {
      content: '';
      position: absolute;
      top: 0;

      display: block;
      width: 4px;
      height: 4px;
      background-color: #f6c39c;
    }
  }
`;

export const LoadingBar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setStage(STAGE.INSTRUCTIONS));
    }, 4000);
  }, [dispatch]);

  return (
    <Styles progress={40}>
      <div className="left-outer" />
      <div className="left-inner" />

      <div className="loading-section">
        <Timeline target={<div className="progress" />}>
          <Tween from={{ width: 0 }} to={{ width: '100%' }} duration={4} />
        </Timeline>
      </div>

      <div className="right-inner" />
      <div className="right-outer" />
    </Styles>
  );
};
