import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { App } from './components/App/App';
import { NoMatch } from './components/NoMatch/NoMatch';

import store from './store/store';
import reportWebVitals from './reportWebVitals';

import { GlobalStyles } from './common/styles/GlobalStyles';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyles>
        <Router>
          <Switch>
            <Route path="/">
              <App />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </GlobalStyles>
    </Provider>
  </React.StrictMode>,
  // React CRA can do whatever it wants
  // eslint-disable-next-line unicorn/prefer-query-selector
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
