import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Header } from '../Header/Header';
import { Canvas } from '../Canvas/Canvas';

import { IntroUI } from '../../ui-stages/IntroUI/IntroUI';
import { InstructionsUI } from '../../ui-stages/InstructionsUI/InstructionsUI';
import { LoadingUI } from '../../ui-stages/LoadingUI/LoadingUI';
import { SpotifyInstructionsUI } from '../../ui-stages/SpotifyInstructionsUI/SpotifyInstructionsUI';
import { GameUI } from '../../ui-stages/GameUI/GameUI';
import { GameOverUI } from '../../ui-stages/GameOverUI/GameOverUI';

import { STAGE } from '../../common/constants/stages';
import { selectStage } from '../../store/slices/appSlice';

import { Footer } from '../Footer/Footer';
import { CookieBanner } from '../CookieBanner/CookieBanner';
import { EventsListener } from '../EventsListener/EventsListener';
import { SFXLoading } from '../../common/SFX/SFXLoading';

import('focus-visible');

const AppLayout = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* height: 100vh; */
  align-content: space-between;
`;

export function App() {
  const stage = useSelector(selectStage);

  useEffect(() => {
    // init ReactGA and track pageview
    const id = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (id) {
      ReactGA.initialize(id);
      ReactGA.pageview('/');
    }
  }, []);

  return (
    <AppLayout as="main">
      <EventsListener />
      <SFXLoading />

      {(stage === STAGE.GAME ||
        stage === STAGE.SPOTIFY_INSTRUCTIONS ||
        stage === STAGE.GAME_OVER) && <Header />}

      {stage === STAGE.INTRO && <IntroUI />}
      {stage === STAGE.LOADING && <LoadingUI />}
      {stage === STAGE.INSTRUCTIONS && <InstructionsUI />}
      {stage === STAGE.SPOTIFY_INSTRUCTIONS && <SpotifyInstructionsUI />}
      {stage === STAGE.GAME && (
        <>
          <GameUI />
          <Footer />
        </>
      )}
      {stage === STAGE.GAME_OVER && <GameOverUI />}

      <CookieBanner />

      {stage !== STAGE.INTRO && <Canvas />}
    </AppLayout>
  );
}
