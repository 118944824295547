import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Footer } from '../Footer/Footer';
import staticBkgAsset from '../../assets/images/static-bkg.png';

const UiBg = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: ${(props) =>
    props.transparent ? 'transparent' : `url(${staticBkgAsset});`};
  background-color: ${(props) =>
    props.transparent ? 'rgba(0, 0, 0, 0.4);' : `#111111`};

  background-size: cover;
  position: relative;
`;

export const UIBackground = ({ children, transparent, minHeight }) => (
  <UiBg transparent={transparent} minHeight={minHeight}>
    {children}
    <Footer />
  </UiBg>
);

UIBackground.propTypes = {
  children: PropTypes.node.isRequired,
  minHeight: PropTypes.bool,
  transparent: PropTypes.bool,
};
