import { configureStore } from '@reduxjs/toolkit';
import appSliceReducer from './slices/appSlice';
import gameSliceReducer from './slices/gameSlice';

export default configureStore({
  reducer: {
    app: appSliceReducer,
    game: gameSliceReducer,
  },
  devTools: true,
});
