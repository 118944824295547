import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../common/constants/responsive';
import { minMaxSizing } from '../../utils/typeStyling';

export const StyledButton = styled.div`
  && {
    ${minMaxSizing({ css: 'font-size', min: 21, max: 30 })};
    line-height: 1;
    color: white;
    text-transform: uppercase;

    position: relative;
    padding: 0.2em 1.2em 0.5em;
    border-top-width: 0;
    border-width: 2px;

    @media (min-width: ${BREAKPOINTS.SMALL}) {
      border-width: 3px;
    }

    @media (min-width: ${BREAKPOINTS.MEDIUM}) {
      border-width: 4px;
    }
    border-style: solid;
    border-image: linear-gradient(to bottom, #ec5535, #b62000) 0 100%;

    &:hover {
      background: linear-gradient(180deg, #ec5535 0%, #b62000 100%);
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;

      left: -2px;
      width: calc(100% + 4px);
      height: 2px;

      @media (min-width: ${BREAKPOINTS.SMALL}) {
        height: 3px;
        left: -3px;
        width: calc(100% + 6px);
      }

      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        height: 4px;
        left: -4px;
        width: calc(100% + 8px);
      }
    }

    &:before {
      background-color: #ec5535;

      top: -2px;
      @media (min-width: ${BREAKPOINTS.SMALL}) {
        top: -3px;
      }
      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        top: -4px;
      }
    }

    &:after {
      background-color: #b62000;
      bottom: -2px;
      @media (min-width: ${BREAKPOINTS.SMALL}) {
        bottom: -3px;
      }
      @media (min-width: ${BREAKPOINTS.MEDIUM}) {
        bottom: -4px;
      }
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        background: none;
      }
    }
  }
`;

export const Button = ({
  text,
  className = '',
  onClick,
  href,
  as = 'button',
  disabled = false,
}) => (
  <StyledButton
    as={as}
    type="button"
    href={href}
    className={`bitmap-pro ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {text && text}
  </StyledButton>
);

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
