import styled from 'styled-components';
import { ROUTE } from '../../../../common/constants/routes';
import { BREAKPOINTS } from '../../../../common/constants/responsive';
import { trackEvent } from '../../../../utils/trackEvent';
import { ACTION, CATEGORY } from '../../../../common/constants/tracking';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .legal-nav-wrapper {
    display: flex;
    @media (min-width: ${BREAKPOINTS.SMALL}) {
      margin-left: calc(100% / 3);
    }

    width: calc(100% / 2);
    text-align: left;
    @media (min-width: ${BREAKPOINTS.SMALL}) {
      text-align: center;
    }
  }

  .legal-nav {
    @media (min-width: ${BREAKPOINTS.SMALL}) {
      margin: 0 auto;
    }

    ul {
      margin: 0 -12px;

      li {
        padding: 0 12px;
        display: inline;
      }
    }
  }

  .copyright {
    padding-left: 24px;
    width: calc(100% / 2);
    text-align: right;
    opacity: 0.6;
  }
`;

export const LegalFooter = () => {
  const timestamp = new Date();

  return (
    <Wrapper as="section">
      <div className="legal-nav-wrapper">
        <nav className="legal-nav">
          <ul>
            <li>
              <a
                href={ROUTE.LEGAL}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() =>
                  trackEvent(
                    CATEGORY.FOOTER,
                    ACTION.CLICK,
                    'external-link-legal'
                  )
                }
              >
                Legal
              </a>
            </li>
            <li>
              <a
                href={ROUTE.PRIVACY}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() =>
                  trackEvent(
                    CATEGORY.FOOTER,
                    ACTION.CLICK,
                    'external-link-privacy'
                  )
                }
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href={ROUTE.COOKIES}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() =>
                  trackEvent(
                    CATEGORY.FOOTER,
                    ACTION.CLICK,
                    'external-link-cookies'
                  )
                }
              >
                Cookies
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="copyright">© {timestamp.getFullYear()} Spotify AB</div>
    </Wrapper>
  );
};
