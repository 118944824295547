import { Vector2 } from 'three';
// Vector XZ in this case. We never need to move the opponent up, except for the jump but that's not relevant here.
const DIRECTION = {
  FORWARD: new Vector2(0, 1),
  RIGHT: new Vector2(1, 0),
  BACK: new Vector2(0, -1),
  LEFT: new Vector2(-1, 0),
  STILL: new Vector2(0, 0),
};

export default DIRECTION;
