import CC0Asset from '../assets/images/countdown-text-orange/0.svg';
import CC1Asset from '../assets/images/countdown-text-orange/1.svg';
import CC2Asset from '../assets/images/countdown-text-orange/2.svg';
import CC3Asset from '../assets/images/countdown-text-orange/3.svg';
import CC4Asset from '../assets/images/countdown-text-orange/4.svg';
import CC5Asset from '../assets/images/countdown-text-orange/5.svg';
import CC6Asset from '../assets/images/countdown-text-orange/6.svg';
import CC7Asset from '../assets/images/countdown-text-orange/7.svg';
import CC8Asset from '../assets/images/countdown-text-orange/8.svg';
import CC9Asset from '../assets/images/countdown-text-orange/9.svg';

export const renderOrangeNumeral = (number) => {
  switch (number) {
    case '0':
      return CC0Asset;
    case '1':
      return CC1Asset;
    case '2':
      return CC2Asset;
    case '3':
      return CC3Asset;
    case '4':
      return CC4Asset;
    case '5':
      return CC5Asset;
    case '6':
      return CC6Asset;
    case '7':
      return CC7Asset;
    case '8':
      return CC8Asset;
    case '9':
      return CC9Asset;

    default:
      break;
  }
};
